<template>
    <div>
        <confirmation-modal
            v-model="modals.confirmation_delete.show"
            :confirmation_message="
                modals.confirmation_delete.confirmation_message
            "
            :confirmation_sub_message="
                modals.confirmation_delete.confirmation_sub_message
            "
            @confirmed="handle_confirmation_delete_modal"
        >
        </confirmation-modal>

        <cru-operating-cost
            :show="modals.cru.show"
            :expense_id="modals.cru.expense_id"
            :investment_id="investment_id"
            @expense_changed="handle_expense_changed"
        ></cru-operating-cost>

        <b-card>
            <b-card-body class="p-0">
                <b-row class="pb-2">
                    <b-col>
                        <h6 class="heading-small text-muted mb-4">
                            Operating Costs

                            <Transition>
                                <i
                                    v-if="$apollo.loading"
                                    class="fas fa-spinner fa-spin"
                                ></i>
                            </Transition>
                        </h6>
                    </b-col>

                    <b-col class="text-right pr-2">
                        <i
                            class="fa fa-plus pull-right icon icon-shape rounded-circle shadow bg-default text-white"
                            @click="toggle_cru_show('create')"
                        >
                        </i>
                    </b-col>
                </b-row>
                <b-row class="d-none d-md-block">
                    <b-col>
                        <Transition>
                            <el-table
                                class="table-responsive table-flush"
                                header-row-class-name="thead-light"
                                :data="operating_costs"
                            >
                                <el-table-column
                                    label="Name"
                                    min-width="150px"
                                    prop="name"
                                    sortable
                                >
                                    <!-- <template v-slot="{ row }">
                                        <b-media no-body class="align-items-center">
                                            <a
                                                href="#"
                                                class="avatar rounded-circle mr-3"
                                            >
                                                <b-img
                                                    alt="Image placeholder"
                                                    :src="row.img"
                                                ></b-img>
                                            </a>
                                            <b-media-body>
                                                <span
                                                    class="font-weight-600 name mb-0 text-sm"
                                                    >{{ row.title }}</span
                                                >
                                            </b-media-body>
                                        </b-media>
                                    </template> -->
                                </el-table-column>
                                <el-table-column
                                    label="Cost"
                                    prop="cost.formatted"
                                    width="120px"
                                    sortable
                                >
                                </el-table-column>
                                <el-table-column width="160px" align="right">
                                    <template #default="scope">
                                        <base-button
                                            type="default"
                                            size="sm"
                                            :pill="true"
                                            @click="
                                                handle_update_button(
                                                    scope.row.id
                                                )
                                            "
                                        >
                                            <i class="fas fa-wrench"></i>
                                        </base-button>
                                        <base-button
                                            type="danger"
                                            size="sm"
                                            :pill="true"
                                            @click="
                                                handle_delete_button(
                                                    scope.row.id,
                                                    scope.row.name
                                                )
                                            "
                                        >
                                            <i class="fa fa-trash"></i>
                                        </base-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </Transition>
                    </b-col>
                </b-row>
                <b-row class="d-block d-md-none">
                    <b-col>
                        <operating-costs-item
                            v-for="operating_cost in operating_costs"
                            :key="operating_cost.id"
                            :expense_id="operating_cost.id"
                            :expense_name="operating_cost.name"
                            :expense_amount="operating_cost.cost.amount"
                            :expense_currency="operating_cost.cost.currency"
                            @edit="handle_update_button"
                            @delete="handle_delete_button"
                        >
                        </operating-costs-item>
                    </b-col>
                </b-row>
                <!-- <b-row class="pt-1">
                    <b-col class="text-center"
                        ><router-link
                            to="create"
                            class="pull-right icon icon-shape rounded-circle shadow bg-default text-white"
                        >
                            <i class="fa fa-plus"></i>
                        </router-link>
                    </b-col>
                </b-row> -->
                <!-- <hr /> -->
                <b-row class="pt-md-2">
                    <!-- Title -->
                    <b-col
                        sm="12"
                        md="6"
                        class="ml-4 text-left align-middle my-auto"
                    >
                        <h2>Total Monthly Costs</h2>
                    </b-col>
                    <!-- Cost amount -->
                    <b-col
                        class="mr-4 ml-4 ml-md-0 text-left text-md-right align-middle my-auto"
                    >
                        <h1 class="display-3 text-orange text-nowrap">
                            {{ total_costs.currency }}
                            {{ formatted_currency(total_costs.amount) }}
                        </h1>
                    </b-col>
                    <b-col sm="1"></b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
//Custom Components
import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
} from "element-ui";

// Queries
import { GET_ALL_INVESTMENT_RECURRING_EXPENSES } from "@/graphql/queries";

// Mutations
import { DELETE_INVESTMENT_RECURRING_EXPENSE } from "@/graphql/mutations";

// Standard Components
import ConfirmationModal from "@/views/Components/ConfirmationModal.vue";

// Components
import CruOperatingCost from "@/views/Components/Listing/Finances/Forms/CRUOperatingCost.vue";
import OperatingCostsItem from "@/views/Components/Listing/Finances/Forms/OperatingCostsItem.vue";

export default {
    name: "OperatingCosts",
    components: {
        [Table.name]: Table,
        [TableColumn.name]: TableColumn,
        [Dropdown.name]: Dropdown,
        [DropdownItem.name]: DropdownItem,
        [DropdownMenu.name]: DropdownMenu,
        ConfirmationModal,
        CruOperatingCost,
        OperatingCostsItem,
    },

    props: {
        investment_id: {
            type: String,
            description: "Investment ID",
            default: "SW52ZXN0bWVudE5vZGU6MQ==", // TODO CHANGE THIS
        },
    },
    apollo: {
        get_all_investment_recurring_expenses: {
            query: GET_ALL_INVESTMENT_RECURRING_EXPENSES,
            result(data) {
                this.handle_get_all_investment_recurring_expenses(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_all_investment_recurring_expenses = data;
            },
            variables() {
                return {
                    investment_id: this.investment_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            modals: {
                confirmation_delete: {
                    show: false,
                    confirmation_message: "",
                    deleting_id: "",
                },
                cru: { show: false, expense_id: "" },
            },
            apollo_data: {
                get_all_investment_recurring_expenses: null,
            },
            operating_costs: [
                // {
                //     id: "",
                //     name: "Test Name",
                //     cost: {
                //         amount: 100,
                //         currency: "R",
                //         formatted: "R 100",
                //     },
                // },
            ],
            total_costs: {
                amount: 0,
                currency: "R",
            },
        };
    },
    methods: {
        // Modal handlers

        handle_expense_changed(id) {
            this.modals.cru.expense_id = id;
            this.enable_get_all_investment_recurring_expenses();
        },
        handle_delete_button(id, name) {
            this.modals.confirmation_delete.confirmation_message = `You are about to delete: '${name}'`;
            this.modals.confirmation_delete.show = true;
            this.modals.confirmation_delete.deleting_id = id;
        },

        handle_update_button(id) {
            this.modals.cru.expense_id = id;
            this.toggle_cru_show("edit");
        },

        handle_confirmation_delete_modal() {
            this.$apollo
                .mutate({
                    mutation: DELETE_INVESTMENT_RECURRING_EXPENSE,
                    variables: {
                        cost_id: this.modals.confirmation_delete.deleting_id,
                    },
                })
                .then((res) => {
                    this.enable_get_all_investment_recurring_expenses();
                });
        },

        toggle_cru_show(mode) {
            if (mode === "create") {
                this.modals.cru.expense_id = "";
            }
            this.modals.cru.show = false;
            setTimeout(() => {
                this.modals.cru.show = true;
            }, 100);
        },

        // Apolo Handlers
        handle_get_all_investment_recurring_expenses(data) {
            this.operating_costs = [];
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            flattened.allInvestmentRecurringExpense.forEach((element) => {
                this.operating_costs.push({
                    id: element.id,
                    name: element.description,
                    cost: {
                        amount: element.cost__amount,
                        currency: element.cost__currency__symbol,
                        formatted: `${
                            element.cost__currency__symbol
                        } ${this.formatted_currency(element.cost__amount)}`,
                    },
                });
                this.get_total_costs();
            });
            if (flattened.allInvestmentRecurringExpense.length === 0) {
                this.get_total_costs();
            }
        },

        // Apollo Managers
        enable_get_all_investment_recurring_expenses() {
            this.$apollo.queries.get_all_investment_recurring_expenses.setOptions(
                {
                    fetchPolicy: "network-only",
                }
            );
            if (
                !this.$apollo.queries.get_all_investment_recurring_expenses.skip
            ) {
                this.$apollo.queries.get_all_investment_recurring_expenses.refetch();
            } else {
                this.$apollo.queries.get_all_investment_recurring_expenses.skip = false;
                this.$apollo.queries.get_all_investment_recurring_expenses.start();
            }
            if (
                !this.$apollo.queries.get_all_investment_recurring_expenses.skip
            ) {
                this.$apollo.queries.get_all_investment_recurring_expenses.refetch();
            } else {
                this.$apollo.queries.get_all_investment_recurring_expenses.skip = false;
                this.$apollo.queries.get_all_investment_recurring_expenses.start();
            }
        },

        formatted_currency(value) {
            return value.toLocaleString();
        },

        // Data maintainers
        get_total_costs() {
            this.total_costs.amount = 0;
            this.operating_costs.forEach((element) => {
                this.total_costs.amount += element.cost.amount;
            });
        },
        update_formatted_currency() {
            for (let i = 0; i < this.operating_costs.length; i++) {
                this.operating_costs[i].cost.formatted = `${
                    this.operating_costs[i].cost.currency
                } ${this.formatted_currency(
                    this.operating_costs[i].cost.amount
                )}`;
            }
        },
    },
    watch: {
        investment_id() {
            if (this.investment_id !== "") {
                this.enable_get_all_investment_recurring_expenses();
            }
        },
    },
    mounted() {
        this.update_formatted_currency();
        if (this.investment_id !== "") {
            this.enable_get_all_investment_recurring_expenses();
        }
    },
    created(){
        global_event_emitter.$on("operating_cost_update", () => {
            setTimeout(() => {
                this.enable_get_all_investment_recurring_expenses();
            }, 500);
        });
    }, 
    beforeDestroy() {
        global_event_emitter.$off("operating_cost_update");
    },
};
</script>
<style></style>
