<template>
    <div>
        <!-- Header -->
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            My Portfolio
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            Building wealth, one step at a time.
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- BODY -->
        <b-container class="mt--6">
            <!-- Investments -->
            <b-row>
                <b-col><h1 class="text-orange">My Investments</h1></b-col>
            </b-row>
            <b-row class="" v-if="!$apollo.loading">
                <b-col>
                    <b-row v-if="portfolio_items.length < 1">
                        <b-col class="text-center">
                            <h3>You don't have any investments yet.</h3>
                            <router-link
                                :to="{
                                    path: `/listing/discover`,
                                }"
                            >
                                <base-button
                                    class="shadow bg-default text-white"
                                    pill
                                >
                                    Discover Opportunities
                                </base-button>
                            </router-link>
                        </b-col>
                    </b-row>
                    <div
                        v-for="portfolio_item in portfolio_items"
                        :key="portfolio_item.id"
                    >
                        <portfolio-investment-item
                            :id="portfolio_item.id"
                            :name="portfolio_item.name"
                            :address="portfolio_item.address"
                            :cashflow="portfolio_item.cashflow"
                            :buying_price="portfolio_item.buying_price"
                            :bathrooms="portfolio_item.bathrooms"
                            :bedrooms="portfolio_item.bedrooms"
                            :tags="portfolio_item.tags"
                            :type="portfolio_item.type"
                            :status="portfolio_item.status"
                        ></portfolio-investment-item>
                    </div>
                </b-col>
            </b-row>
            <b-row v-else>
                <h1 class="text-center">
                    <i class="fas fa-spinner fa-spin"></i></h1
            ></b-row>
            <!-- Interested Listings -->
            <b-row class="mt-5">
                <b-col><h1 class="text-orange">Enquired Listings</h1></b-col>
            </b-row>
            <b-row v-if="!$apollo.loading">
                <b-col>
                    <b-row v-if="interested_listings.length < 1">
                        <b-col class="text-center">
                            <h3>
                                You don't have any listings that you've enquired
                                about.
                            </h3>
                            <router-link
                                :to="{
                                    path: `/listing/discover`,
                                }"
                            >
                                <base-button
                                    class="shadow bg-default text-white"
                                    pill
                                >
                                    Find Listings
                                </base-button>
                            </router-link>
                        </b-col>
                    </b-row>
                    <div
                        v-for="interested_listing in interested_listings"
                        :key="interested_listing.id"
                    >
                        <portfolio-interested-item
                            :id="interested_listing.id"
                            :name="interested_listing.name"
                            :address="interested_listing.address"
                            :cashflow="interested_listing.cashflow"
                            :buying_price="interested_listing.buying_price"
                            :bathrooms="interested_listing.bathrooms"
                            :bedrooms="interested_listing.bedrooms"
                            :tags="interested_listing.tags"
                            :type="interested_listing.type"
                            :status="interested_listing.status"
                        ></portfolio-interested-item>
                    </div>
                </b-col>
            </b-row>
            <b-row v-else>
                <h1 class="text-center">
                    <i class="fas fa-spinner fa-spin"></i></h1
            ></b-row>
        </b-container>
    </div>
</template>

<script>
// Component imports
import PortfolioInvestmentItem from "@/views/Components/Portfolio/PortfolioInvestmentItem.vue";
import PortfolioInterestedItem from "@/views/Components/Portfolio/PortfolioInterestedItem.vue";

// Queries
import { GET_PROPERTY_LISTINGS_INTEREST_USER } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_OWNED_BY_USER } from "@/graphql/queries";

export default {
    name: "MyPortfolio",
    components: {
        PortfolioInvestmentItem,
        PortfolioInterestedItem,
    },
    emits: [],
    props: {},
    apollo: {
        get_all_property_listings_interest_user: {
            query: GET_PROPERTY_LISTINGS_INTEREST_USER,
            result(data) {
                let flattened = graph_utils.flatten_relay_response(data.data);
                this.interested_listings = [];
                flattened.allPropertyInterest.forEach((el) => {
                    this.interested_listings.push({
                        id: el.listing__id,
                        name: el.listing__name,
                        address: el.listing__address__cityName,
                        buying_price: {
                            symbol: el.listing__buyingPrice__currency__symbol,
                            amount: el.listing__buyingPrice__amount,
                        },
                        cashflow: {
                            symbol: el.listing__totalExpectedRentalIncome__currency__symbol,
                            amount: el.listing__totalExpectedRentalIncome__amount,
                        },
                        tags: [],
                        type: el.listing__listingType__name,
                        status: el.listing__status__friendlyName,
                    });
                });
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_all_property_listings_interest_user = data;
            },
            variables() {
                return {
                    user_id: store.getters.getUserIdB64,
                };
            },
        },
        get_all_property_listing_owned_by_user: {
            query: GET_PROPERTY_LISTING_OWNED_BY_USER,
            result(data) {
                let flattened = graph_utils.flatten_relay_response(data.data);
                this.portfolio_items = [];
                flattened.allPropertyListing.forEach((el) => {
                    this.portfolio_items.push({
                        id: el.id,
                        name: el.name,
                        address: el.address__gAddress,
                        buying_price: {
                            symbol: el.buyingPrice__currency__symbol,
                            amount: el.buyingPrice__amount,
                        },
                        cashflow: {
                            symbol: el.totalExpectedRentalIncome__currency__symbol,
                            amount: el.totalExpectedRentalIncome__amount,
                        },
                        tags: [],
                        type: el.listingType__name,
                        status: el.status__friendlyName,
                    });
                });
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_all_property_listing_owned_by_user = data;
            },
            variables() {
                return {
                    user_id: store.getters.getUserIdB64,
                };
            },
        },
    },
    data() {
        return {
            apollo_data: {
                get_all_property_listings_interest_user: null,
                get_all_property_listing_owned_by_user: null,
            },
            portfolio_items: [],
            interested_listings: [],
        };
    },
    methods: {
        reload_data() {
            this.$apollo.queries.get_all_property_listings_interest_user.refetch();
            this.$apollo.queries.get_all_property_listing_owned_by_user.refetch();
        },
    },
    mounted() {
        this.reload_data();
    },
};
</script>

<style></style>
