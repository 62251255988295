var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-body',{staticClass:"p-0"},[_c('b-row',[_c('b-col',{attrs:{"sm":"3"}},[_c('b-row',[_c('b-col',[_c('h1',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm.name)+" ")])])],1),_c('b-row',[_c('b-col',[(_vm.creator_first_name)?_c('small',{staticClass:"text-muted"},[_vm._v(" Lister: "+_vm._s(_vm.creator_first_name)+" "+_vm._s(_vm.creator_last_name)+" ")]):_c('small',{staticClass:"text-muted"},[_vm._v(" Lister: Unknown ")])])],1),_c('b-row',[_c('b-col',[_c('small',{staticClass:"text-muted"},[_c('i',{staticClass:"fa-solid fa-house"}),_vm._v(" "+_vm._s(_vm.type)+" ")])])],1),_c('b-row',[_c('b-col',[_c('small',{staticClass:"text-muted"},[_c('i',{staticClass:"ni ni-pin-3"}),_vm._v(" "+_vm._s(_vm.address)+" ")])])],1)],1),_c('b-col',{attrs:{"sm":"1","lg":"2"}},[_c('b-row',{staticClass:"pt-2"},[_c('b-col',{staticClass:"px-0"},[_c('badge',{staticClass:"mx-1",attrs:{"rounded":true,"size":"md","type":_vm.status_type}},[_vm._v(_vm._s(_vm.status)+" ")]),(
                                    _vm.approval_status || _vm.approval_status.name
                                )?_c('badge',{staticClass:"mx-1",attrs:{"rounded":true,"size":"md","type":_vm.approval_status_style}},[_vm._v(_vm._s(_vm.approval_status.friendly_name))]):_vm._e(),_vm._l((_vm.tags),function(tag){return _c('badge',{key:tag.text,staticClass:"mx-1",attrs:{"rounded":true,"size":"md","type":tag.type}},[_vm._v(_vm._s(tag.text))])})],2)],1)],1),_c('b-col',{attrs:{"sm":"3","lg":"2"}},[_c('b-row',{staticClass:"mt-3"},[(_vm.bedrooms)?_c('b-col',{staticClass:"p-0",attrs:{"lg":"4"}},[_c('p',{staticClass:"text-muted font-weight-bold"},[_c('i',{staticClass:"fa fa-bed"}),_vm._v(" "+_vm._s(_vm.bedrooms)+" ")])]):_vm._e(),(_vm.bathrooms)?_c('b-col',{staticClass:"p-0",attrs:{"lg":"4"}},[_c('p',{staticClass:"text-muted font-weight-bold"},[_c('i',{staticClass:"fa fa-bath"}),_vm._v(" "+_vm._s(_vm.bathrooms)+" ")])]):_vm._e()],1)],1),_c('b-col',{attrs:{"sm":"5"}},[_c('b-row',[_c('b-col',[(_vm.cashflow.amount != 0)?_c('div',[_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb--2 font-weight-bold"},[_vm._v(" Rental Income ")])])],1),_c('b-row',[_c('b-col',{staticClass:"ml-2"},[_c('h1',{staticClass:"text-orange font-weight-bold m-0 text-nowrap"},[_vm._v(" "+_vm._s(_vm.cashflow.symbol)+" "+_vm._s(_vm.formatted_currency( _vm.cashflow.amount ))+" ")])])],1)],1):_vm._e()]),_c('b-col',[(_vm.buying_price.amount != 0)?_c('div',[_c('b-row',[_c('b-col',[_c('p',{staticClass:"mb--2 font-weight-bold"},[_vm._v(" Purchase Price ")])])],1),_c('b-row',[_c('b-col',{staticClass:"ml-2"},[_c('h1',{staticClass:"text-black-50 font-weight-bold m-0 text-nowrap"},[_vm._v(" "+_vm._s(_vm.buying_price.symbol)+" "+_vm._s(_vm.formatted_currency( _vm.buying_price.amount ))+" ")])])],1)],1):_vm._e()])],1),_c('b-row',[_c('b-col'),_c('b-col',{staticClass:"text-right col-md-auto px-0"},[(_vm.id)?_c('router-link',{attrs:{"to":{
                                    path: ("/listing/manage/" + _vm.id),
                                }}},[_c('base-button',{staticClass:"mt-4",attrs:{"type":"default","pill":true}},[_vm._v(" View ")])],1):_vm._e()],1),_c('b-col',{staticClass:"text-right col-md-auto px-0 mt-3"},[_c('b-dropdown',{attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":"","dropleft":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-ellipsis-v text-muted"})]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"to":{
                                        path: ("/listing/edit?id=" + _vm.id),
                                    }}},[_vm._v("Edit Detail")]),_c('b-dropdown-item',{attrs:{"to":{
                                        path: ("/listing/manage-investments?lid=" + _vm.id),
                                    }}},[_vm._v("Edit Investment")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }