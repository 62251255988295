<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="">
                <b-row>
                    <b-col class="mt-4">
                        <div
                            @click="$router.back()"
                            class="icon icon-shape rounded-circle shadow bg-default text-white"
                        >
                            <i class="fa fa-arrow-left"></i>
                        </div>
                    </b-col>
                    <b-col lg="11" class="">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            Manage Investment
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            {{ listing_name }}
                            <Transition>
                                <i
                                    v-if="$apollo.loading"
                                    class="fas fa-spinner fa-spin"
                                ></i>
                            </Transition>
                        </p>
                    </b-col>
                </b-row>
                <b-row v-if="investment_id !== null">
                    <b-col>
                        <b-row>
                            <b-col>
                                <rental-income-finance :listing_id="listing_id">
                                </rental-income-finance>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <operating-costs :investment_id="investment_id">
                                </operating-costs>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <investment-financing
                                    :investment_id="investment_id"
                                >
                                </investment-financing>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <buying-costs :investment_id="investment_id">
                                </buying-costs>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6"> </b-container>
    </div>
</template>
<script>
// Queries
import { GET_PROPERTY_LISTING_INVESTMENTS } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_NAME } from "@/graphql/queries";

import OperatingCosts from "@/views/Components/Listing/Finances/Forms/OperatingCosts.vue";
import BuyingCosts from "@/views/Components/Listing/Finances/Forms/BuyingCosts.vue";
import InvestmentFinancing from "@/views/Components/Listing/Finances/Forms/InvestmentFinancing.vue";
import RentalIncomeFinance from "@/views/Components/Listing/Finances/Forms/RentalIncomeFinance.vue";

export default {
    name: "ListingInvestments",
    components: {
        OperatingCosts,
        BuyingCosts,
        InvestmentFinancing,
        RentalIncomeFinance,
    },
    apollo: {
        get_listing_investments: {
            query: GET_PROPERTY_LISTING_INVESTMENTS,
            result(data) {
                this.handle_get_listing_investments(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_listing_investments = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
        get_listing_name: {
            query: GET_PROPERTY_LISTING_NAME,
            result(data) {
                this.handle_get_listing_name(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_listing_name = data;
            },
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_listing_investments: null,
            },
            listing_id: null,
            investment_id: null,
            listing_name: null,
        };
    },
    methods: {
        // Apollo handlers
        handle_get_listing_investments(data) {
            // Check for valid id push back if needed.
            if ("error" in data && data.error.message.includes("Invalid ID")) {
                alert("ID ISSUES");
            }
            // Handle data
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            if (flattened.allInvestmentInvestment.length > 0) {
                this.investment_id = flattened.allInvestmentInvestment[0].id;
            }
        },

        handle_get_listing_name(data) {
            this.listing_name = data.data.propertyListing.name;
        },

        // Apollo Manage
        enable_get_listing_investments() {
            this.$apollo.queries.get_listing_investments.setOptions({
                fetchPolicy: "network-only",
            });
            if (!this.$apollo.queries.get_listing_investments.skip) {
                this.$apollo.queries.get_listing_investments.refetch();
            } else {
                this.$apollo.queries.get_listing_investments.skip = false;
                this.$apollo.queries.get_listing_investments.start();
            }
        },
        enable_get_listing_name() {
            if (!this.$apollo.queries.get_listing_name.skip) {
                this.$apollo.queries.get_listing_name.refetch();
            } else {
                this.$apollo.queries.get_listing_name.skip = false;
                this.$apollo.queries.get_listing_name.start();
            }
        },

        // Global State
        get_listing_id() {
            this.listing_id = this.$route.query.lid;
        },
    },
    created() {},
    mounted() {
        this.get_listing_id();
    },
    watch: {
        listing_id() {
            if (this.listing_id !== null) {
                this.enable_get_listing_investments();
                this.enable_get_listing_name();
            }
        },
    },
};
</script>
<style></style>
