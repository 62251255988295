var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{staticClass:"p-0",attrs:{"size":"sm","id":"cru-buying-cost-modal","title":_vm.buying_cost_id === ''
                ? 'Create Buying Cost'
                : 'Edit Buying Cost',"ok-title":"Save"},on:{"hidden":_vm.handle_modal_hide,"ok":_vm.handle_ok},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
                var ok = ref.ok;
return [_c('b-button',{attrs:{"variant":"default","disabled":_vm.modal.loading || _vm.$apollo.loading},on:{"click":function($event){return ok()}}},[(_vm.modal.loading || _vm.$apollo.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" Save ")])]}}]),model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_submit)}}},[_c('input',{ref:"expense_form_submit_button",attrs:{"type":"submit","hidden":""}}),_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"label":"Buying Cost Type","name":"Buying Cost Type","rules":{ required: true }}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Select"},on:{"change":_vm.input_on_key_up_debounced},model:{value:(
                                    _vm.form.transaction_cost_type.selection
                                ),callback:function ($$v) {_vm.$set(_vm.form.transaction_cost_type, "selection", $$v)},expression:"\n                                    form.transaction_cost_type.selection\n                                "}},_vm._l((_vm.form
                                        .transaction_cost_type.options),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('base-input',{staticClass:"mb-3",attrs:{"label":"Cost Amount (R)*","type":"number","placeholder":"Enter an amount","name":"Cost Amount","rules":{ required: true },"disabled":_vm.$apollo.loading && !_vm.form.create_mode},on:{"keyup":_vm.input_on_key_up_debounced},model:{value:(_vm.form.cost_amount),callback:function ($$v) {_vm.$set(_vm.form, "cost_amount", $$v)},expression:"form.cost_amount"}})],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }