<template>
    <base-nav
        container-classes="container-fluid"
        class="navbar-top border-bottom navbar-expand"
        :class="{ 'bg-success navbar-dark': type === 'default' }"
    >
        <!-- Search form -->
        <!-- <b-form class="navbar-search form-inline mr-sm-3"
          :class="{'navbar-search-light': type === 'default', 'navbar-search-dark': type === 'light'}"
          id="navbar-search-main">
      <b-form-group class="mb-0">
        <b-input-group class="input-group-alternative input-group-merge">
          <div class="input-group-prepend">
            <span class="input-group-text"><i class="fas fa-search"></i></span>
          </div>
          <b-form-input placeholder="Search" type="text"> </b-form-input>
        </b-input-group>
      </b-form-group>
      <button type="button" class="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
    </b-form> -->
        <!-- Navbar links -->
        <b-navbar-nav class="align-items-center ml-md-auto">
            <!-- This item dont have <b-nav-item> because they add class 'nav-link' which is not needed here -->
            <li class="nav-item d-xl-none">
                <!-- Sidenav toggler -->
                <div
                    class="pr-3 sidenav-toggler"
                    :class="{
                        active: $sidebar.showSidebar,
                        'sidenav-toggler-dark': type === 'default',
                        'sidenav-toggler-light': type === 'light',
                    }"
                    @click="toggleSidebar"
                >
                    <div class="sidenav-toggler-inner">
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                        <i class="sidenav-toggler-line"></i>
                    </div>
                </div>
            </li>
            <!-- This item dont have <b-nav-item> because item have data-action/data-target on tag <a>, wich we cant add -->
            <li class="nav-item d-sm-none">
                <a
                    class="nav-link"
                    href="#"
                    data-action="search-show"
                    data-target="#navbar-search-main"
                >
                    <i class="ni ni-zoom-split-in"></i>
                </a>
            </li>
        </b-navbar-nav>
        <b-navbar-nav class="align-items-center ml-auto ml-md-0">
            <base-dropdown
                menu-on-right
                class="nav-item"
                tag="li"
                title-tag="a"
                title-classes="nav-link pr-0"
            >
                <a
                    href="#"
                    class="nav-link pr-0"
                    @click.prevent
                    slot="title-container"
                >
                    <b-media no-body class="align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                            {{ getUserStartingLetters() }}
                        </span>
                        <!-- <b-media-body class="ml-2 d-none d-lg-block">
              <span class="mb-0 text-sm  font-weight-bold">John Snow</span>
            </b-media-body> -->
                    </b-media>
                </a>

                <template>
                    <b-dropdown-header class="noti-title">
                        <h6 class="text-overflow m-0">Welcome!</h6>
                    </b-dropdown-header>
                    <b-dropdown-item :to="{ path: '/user/profile' }">
                        <i class="ni ni-single-02"></i>
                        <span>My profile</span>
                    </b-dropdown-item>
                    <!-- <b-dropdown-item href="#!">
            <i class="ni ni-settings-gear-65"></i>
            <span>Settings</span>
          </b-dropdown-item> -->
                    <!-- <b-dropdown-item href="#!">
            <i class="ni ni-calendar-grid-58"></i>
            <span>Activity</span>
          </b-dropdown-item> -->
                    <b-dropdown-item href="https://investrand.freshdesk.com/support/tickets/new" target="_blank">
                        <i class="ni ni-support-16"></i>
                        <span>Support</span>
                    </b-dropdown-item>
                    <div class="dropdown-divider"></div>
                    <b-dropdown-item :to="{ path: '/logout' }">
                        <i class="ni ni-user-run"></i>
                        <span>Logout</span>
                    </b-dropdown-item>
                </template>
            </base-dropdown>
        </b-navbar-nav>
    </base-nav>
</template>
<script>
import { BaseNav } from "@/components";


export default {
    components: {
        BaseNav,
    },
    props: {
        type: {
            type: String,
            default: "default", // default|light
            description:
                "Look of the dashboard navbar. Default (Green) or light (gray)",
        },
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },
    },
    data() {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: "",
            user: {},
            session_data_poller: null, 
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        loadUserData() {
            this.user = utils.deepcopy(this.$store.getters.getUser);
        },
        getUserStartingLetters() {
            if (this.user.first_name && this.user.last_name){
            return (
                Array.from(this.user.first_name)[0] +
                Array.from(this.user.last_name)[0]
            );
            }
            else{
                return "";
            }
        },
        maintain_layout_data() {
            this.session_data_poller = setInterval(() => {
                this.loadUserData()
                this.getUserStartingLetters();
            }, 5000);
        },
    },
    created() {
        setTimeout(() => {
            this.loadUserData();
        }, 500);

        global_event_emitter.$on("profile_update", () => {
            setTimeout(() => {
                this.loadUserData();
            }, 1000);
        });
        this.maintain_layout_data(); 
    },
    beforeDestroy() {
        global_event_emitter.$off("profile_update");
        clearInterval(this.session_data_poller)
    },
};
</script>
