<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            My Listings
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            Let's create some value!
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row class="mb-4" v-if="property_listings.length != 0">
                <b-col sm="11"></b-col>

                <!-- Search -->
                <!-- <b-col sm="3">
                    <b-form
                        class="navbar-search form-inline mr-sm-3 navbar-search-light"
                        id="navbar-search-main"
                    >
                        <b-form-group class="mb-0">
                            <b-input-group
                                class="input-group-alternative input-group-merge"
                            >
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        ><i class="fas fa-search"></i
                                    ></span>
                                </div>
                                <b-form-input placeholder="Search" type="text">
                                </b-form-input>
                            </b-input-group>
                        </b-form-group>
                        <button
                            type="button"
                            class="close"
                            data-action="search-close"
                            data-target="#navbar-search-main"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </b-form>
                </b-col> -->

                <b-col sm="1" class="pl-5"
                    ><router-link
                        to="create"
                        class="pull-right icon icon-shape rounded-circle shadow bg-default text-white"
                    >
                        <i class="fa fa-plus"></i>
                    </router-link>
                </b-col>
            </b-row>
            <b-row v-if="property_listings.length == 0 && !$apollo.loading">
                <b-col class="text-center">
                    <h3 class="text-default">Create your first listing</h3>
                </b-col>
            </b-row>
            <b-row v-if="property_listings.length == 0 && !$apollo.loading">
                <b-col class="text-center">
                    <router-link
                        to="create"
                        class="pull-right icon icon-shape rounded-circle shadow bg-default text-white"
                    >
                        <i class="fa fa-plus"></i>
                    </router-link>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12">
                    <h1 class="text-center">
                        <i
                            v-if="$apollo.loading"
                            class="fas fa-spinner fa-spin"
                        ></i>
                    </h1>
                </b-col>
                <b-col sm="12">
                    <div
                        v-for="property_listing in property_listings"
                        :key="property_listing.id"
                    >
                        <listing-dashboard-item
                            :id="property_listing.id"
                            :name="property_listing.name"
                            :address="property_listing.address"
                            :cashflow="property_listing.cashflow"
                            :buying_price="property_listing.buying_price"
                            :bathrooms="property_listing.bathrooms"
                            :bedrooms="property_listing.bedrooms"
                            :tags="property_listing.tags"
                            :type="property_listing.type"
                            :status="property_listing.status"
                            :approval_state="property_listing.approval_state"
                        ></listing-dashboard-item>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import ListingDashboardItem from "@/views/Components/Listing/ListingDashboardItem.vue";
import { GET_ALL_PROPERTY_LISTING_FOR_ACCOUNT } from "@/graphql/queries";
export default {
    name: "MyListings",
    components: {
        ListingDashboardItem,
    },
    apollo: {
        allPropertyListing: {
            query: GET_ALL_PROPERTY_LISTING_FOR_ACCOUNT,
            result(data) {
                this.handleAllPropertyListing(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    account_id: store.getters.getAccountIdB64,
                };
            },
            update(data) {
                this.property_listings = data;
            },
        },
    },

    data() {
        return {
            allPropertyListing: "",
            property_listings: [],
        };
    },
    methods: {
        handleAllPropertyListing(data) {
            if (!data.data.allPropertyListing) {
                return;
            }
            let flattened = graph_utils.flatten_relay_response(
                data.data.allPropertyListing
            );
            this.property_listings = [];
            flattened.forEach((element) => {
                let listing = {
                    id: element.id,
                    name: element.name,
                    address: element.address__gAddress,
                    buying_price: {
                        symbol: element.buyingPrice__currency_symbol || "R",
                        amount: element.buyingPrice__amount,
                    },
                    cashflow: {
                        symbol:
                            element.totalExpectedRentalIncome__currency_symbol ||
                            "R",
                        amount: element.totalExpectedRentalIncome__amount,
                    },
                    bathrooms: 0,
                    bedrooms: 0,
                    tags: [],
                    type: element.listingType__name,
                    status: element.status__friendlyName,
                    approval_state: {
                        name: null,
                        friendly_name: null,
                    },
                };

                if (
                    element.subunits.length == 1 &&
                    element.subunits[0].isOnlySubunit
                ) {
                    if (element.subunits[0].bathroomCount) {
                        listing.bathrooms = parseFloat(
                            element.subunits[0].bathroomCount
                        );
                    }
                    if (element.subunits[0].bedroomCount) {
                        listing.bedrooms = parseFloat(
                            element.subunits[0].bedroomCount
                        );
                    }
                }
                if ("approval__state__friendlyName" in element) {
                    listing.approval_state = {
                        friendly_name: element.approval__state__friendlyName,
                        name: element.approval__state__name,
                    };
                }
                this.property_listings.push(listing);
            });
        },
    },
    mounted() {
        this.$apollo.queries.allPropertyListing.setOptions({
            fetchPolicy: "network-only",
        });
        this.$apollo.queries.allPropertyListing.refetch();
    },
    created() {
        global_event_emitter.$on("listings_update", () => {
            this.$apollo.queries.allPropertyListing.refetch();
        });
    },
    beforeDestroy() {
        global_event_emitter.$off("listings_update");
    },
    
};
</script>
<style>
.profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
}
</style>
