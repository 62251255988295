var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{staticClass:"p-0",attrs:{"size":"lg","id":"sign-contract-modal","title":_vm.contract.name + ' Agreement',"ok-title":"Agree"},on:{"hidden":_vm.handle_modal_hide,"ok":_vm.handle_ok},scopedSlots:_vm._u([{key:"modal-footer",fn:function(ref){
var ok = ref.ok;
return [_c('b-button',{attrs:{"variant":"default","disabled":_vm.modal.loading || _vm.$apollo.loading},on:{"click":function($event){return ok()}}},[(_vm.modal.loading || _vm.$apollo.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),(_vm.modal.success)?_c('i',{staticClass:"fas fa-check"}):_vm._e(),_vm._v(" Agree ")])]}}]),model:{value:(_vm.modal.show),callback:function ($$v) {_vm.$set(_vm.modal, "show", $$v)},expression:"modal.show"}},[_c('b-container',[_c('div',{staticClass:"contract-container mb-5",attrs:{"id":"contract-container"},domProps:{"innerHTML":_vm._s(_vm.contract.html_content)}}),_c('hr',{staticClass:"mr-3"}),_c('validation-observer',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.on_submit)}}},[_c('input',{ref:"form_submit_button",attrs:{"type":"submit","hidden":""}}),_c('b-row',{staticClass:"mb-3"},[_c('b-col',[_c('base-input',{attrs:{"rules":{
                                required: {
                                    allowFalse: false,
                                },
                            },"name":"Agree"}},[_c('b-form-checkbox',{on:{"change":function($event){return _vm.check_get_user_location()}},model:{value:(_vm.form.accepted),callback:function ($$v) {_vm.$set(_vm.form, "accepted", $$v)},expression:"form.accepted"}},[_vm._v(" I Agree to the \""+_vm._s(_vm.contract.name)+"\" Agreement? ")])],1),(!_vm.user_location.manual_location_required)?_c('small',[_vm._v("We will ask for your location to keep a record of this agreement. "),(_vm.user_location.collecting_location)?_c('i',{staticClass:"fas fa-spinner fa-spin ml-1"}):_vm._e()]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('base-input',{attrs:{"rules":{
                                required:
                                    _vm.user_location.manual_location_required,
                            },"name":"Location"}},[_c('b-form-input',{directives:[{name:"show",rawName:"v-show",value:(
                                    _vm.user_location.manual_location_required
                                ),expression:"\n                                    user_location.manual_location_required\n                                "}],attrs:{"placeholder":"Please enter your address.","type":"text"},model:{value:(_vm.user_location.location_text),callback:function ($$v) {_vm.$set(_vm.user_location, "location_text", $$v)},expression:"user_location.location_text"}})],1),(_vm.user_location.manual_location_required)?_c('small',[_vm._v("We could not get your location automatically. Please enter it. ")]):_vm._e()],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }