<template>
    <div>
        <b-card>
            <!-- Card body -->
            <b-card-body class="p-0">
                <!-- <b-card-title title-tag="h5" class="h1 mb-0"
                    >Get started with Argon</b-card-title
                > -->
                <b-row >
                    <!-- Picture -->
                    <b-col sm="12" md="2">
                        <b-img-lazy
                            left
                            :src="picture_url"
                            rounded
                            fluid-grow
                        ></b-img-lazy>
                    </b-col>

                    <b-col sm="12" md="6">
                        <!-- Title -->
                        <b-row>
                            <b-col>
                                <h1 class="font-weight-bold mb-0">
                                    {{ name }}
                                </h1>
                            </b-col>
                        </b-row>
                        <!-- Location -->
                        <b-row class="mt--1" v-if="location.province">
                            <b-col>
                                <small class="text-muted">
                                    <i class="ni ni-pin-3"></i>
                                </small>
                                <small v-if="location.city" class="text-muted">
                                    {{ location.city }}
                                </small>
                                <small
                                    v-if="location.city && location.province"
                                    class="text-muted"
                                >
                                    , {{ location.province }}
                                </small>
                            </b-col>
                        </b-row>
                        <!-- Description -->

                        <b-row class="mt-2">
                            <b-col>
                                <p class="mb-0">
                                    {{ short_description }}
                                </p>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col class="d-flex">
                        <b-row>
                            <b-col>
                                <rating-view-widget-service-provider
                                    v-if="rating_count > 0"
                                    :rating="rating_stars"
                                    :rating_count="rating_count"
                                    align_right
                                ></rating-view-widget-service-provider>
                            </b-col>
                        </b-row>
                        <b-row class="flex-fill">
                            <b-col class="align-self-end text-right">
                                <base-button
                                    type="default"
                                    size="sm"
                                    :pill="true"
                                    m0
                                    @click="
                                        navigate_to_profile(service_provider_id)
                                    "
                                >
                                    View Profile
                                </base-button>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
// Components
import RatingViewWidgetServiceProvider from "@/views/Components/Rating/RatingViewWidgetServiceProvider.vue";

import Badge from "@/components/Badge.vue";
export default {
    name: "ServiceProviderItem",
    components: {
        Badge,
        RatingViewWidgetServiceProvider,
    },
    props: {
        service_provider_id: {
            type: String,
            description: "ID of the service provider",
            default: "",
        },
        service_provider_types: {
            type: Array,
            description: "Array of strings depicting types of service provider",
            default() {
                return [];
            },
        },

        name: {
            type: String,
            description: "Name",
            default: "",
        },
        short_description: {
            type: String,
            description: "Short description of the service provider",
            default: "",
        },
        location: {
            type: Object,
            description: "A location object that contains city and province",
            default() {
                return {
                    city: "",
                    province: "",
                };
            },
        },
        picture_url: {
            type: String,
            description: "The picture url to use for the profile picture",
            default: "",
        },
        type: {
            type: String,
            description: "wide or narrow",
            default: "wide",
        },
        rating_stars: {
            type: Number,
            description: "The rating of the service provider",
            default: 0,
        },
        rating_count: {
            type: Number,
            description:
                "The total number of ratings for the service provider.",
            default: 0,
        },
    },
    data() {
        return {};
    },
    methods: {
        navigate_to_profile(id) {
            this.$router.push({
                path: "/sp/view",
                query: {
                    id: id,
                },
            });
        },
    },
    computed: {},
};
</script>
<style></style>
