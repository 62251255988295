<template>
    <b-modal
        v-model="modal.show"
        size="lg"
        id="sign-contract-modal"
        :title="contract.name + ' Agreement'"
        ok-title="Agree"
        @hidden="handle_modal_hide"
        @ok="handle_ok"
        class="p-0"
    >
        <b-container>
            <div
                id="contract-container"
                class="contract-container mb-5"
                v-html="contract.html_content"
            ></div>
            <hr class="mr-3" />

            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form @submit.prevent="handleSubmit(on_submit)">
                    <input type="submit" ref="form_submit_button" hidden />
                    <b-row class="mb-3">
                        <b-col>
                            <base-input
                                :rules="{
                                    required: {
                                        allowFalse: false,
                                    },
                                }"
                                name="Agree"
                            >
                                <b-form-checkbox
                                    v-model="form.accepted"
                                    @change="check_get_user_location()"
                                >
                                    I Agree to the "{{ contract.name }}"
                                    Agreement?
                                </b-form-checkbox>
                            </base-input>
                            <small
                                v-if="!user_location.manual_location_required"
                                >We will ask for your location to keep a record
                                of this agreement.
                                <i
                                    v-if="user_location.collecting_location"
                                    class="fas fa-spinner fa-spin ml-1"
                                ></i>
                            </small>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <base-input
                                :rules="{
                                    required:
                                        user_location.manual_location_required,
                                }"
                                name="Location"
                            >
                                <b-form-input
                                    v-show="
                                        user_location.manual_location_required
                                    "
                                    placeholder="Please enter your address."
                                    type="text"
                                    v-model="user_location.location_text"
                                >
                                </b-form-input>
                            </base-input>
                            <small v-if="user_location.manual_location_required"
                                >We could not get your location automatically.
                                Please enter it.
                            </small>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-container>

        <template #modal-footer="{ ok }">
            <b-button
                variant="default"
                @click="ok()"
                :disabled="modal.loading || $apollo.loading"
            >
                <i
                    v-if="modal.loading || $apollo.loading"
                    class="fas fa-spinner fa-spin"
                ></i>
                <i v-if="modal.success" class="fas fa-check"></i>
                Agree
            </b-button>
        </template>
    </b-modal>
</template>

<script>
// Compoonents
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// Queries
import { GET_CONTRACT_CONTRACT } from "@/graphql/queries";

// Mutations
import { CREATE_CONTRACT_EXECUTION } from "@/graphql/mutations";
import { emit } from "process";

// Perfect scrollbar functions
function has_element(className) {
    return document.getElementsByClassName(className).length > 0;
}

function init_scrollbar(className) {
    if (has_element(className)) {
        new PerfectScrollbar(`.${className}`, { suppressScrollX: true });
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            init_scrollbar(className);
        }, 100);
    }
}

export default {
    name: "SignContractModal",
    emits: ["contract_signed"],
    components: {},
    props: {
        value: Boolean,
        contract_id: {
            type: String,
            description: "ID of the contract to sign",
            default: null,
        },
    },
    apollo: {
        get_contract: {
            query: GET_CONTRACT_CONTRACT,
            result(data) {
                this.handle_get_contract(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_contract = data;
            },
            variables() {
                return {
                    contract_id: this.contract_id,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            apollo_data: {
                get_contract: null,
            },
            modal: { show: this.value, loading: false, success: false },
            form: {
                accepted: false,

                // rating: { value: null, error: null },
                // headline: null,
                // comment: null,
            },
            contract: {
                name: null,
                html_content: null,
                contract_version_id: null,
            },

            user_location: {
                long: null,
                lat: null,
                location_text: null,
                collecting_location: false,
                collection_success: false,
                manual_location_required: false,
            },
        };
    },
    methods: {
        // Scroll bar
        init_scrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                init_scrollbar("contract-container");
            }
        },

        // Geolocation
        check_get_user_location() {
            if (this.form.accepted) {
                this.get_user_location();
            }
        },
        get_user_location() {
            this.user_location.collecting_location = true;
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.user_location.long = position.coords.longitude;
                        this.user_location.lat = position.coords.latitude;
                        this.user_location.collection_success = true;
                        this.user_location.collecting_location = false;
                        this.user_location.manual_location_required = false;
                    },
                    (error) => {
                        this.user_location.collecting_location = false;
                        this.user_location.manual_location_required = true;
                        console.log(error.message);
                    },
                    {
                        enableHighAccuracy: true,
                        timeout: 20000,
                    }
                );
            } else {
                this.user_location.manual_location_required = true;
            }
        },

        // Form Hanlders
        on_submit() {
            this.modal.loading = true;
            if (this.form.accepted) {
                this.create_new_contract_exectuion();
            } else {
                this.modal.loading = false;
            }
        },

        create_new_contract_exectuion() {
            this.$apollo
                .mutate({
                    mutation: CREATE_CONTRACT_EXECUTION,
                    variables: {
                        contract_id: this.contract_id,
                        user_id: store.getters.getUserIdB64,
                        account_id: store.getters.getAccountIdB64,
                        contract_version_id: this.contract.contract_version_id,
                        location: this.user_location.location_text,
                        gps_long: this.user_location.long,
                        gps_lat: this.user_location.lat,
                    },
                })
                .then((res) => {
                    this.hide_modal();
                    this.$emit("contract_signed");
                })

                .catch((res) => {
                    this.modal.loading = false;
                });
        },

        // Apollo Handlers
        handle_get_contract(data) {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );
            this.contract.name = flattened.contractContract__name;
            this.contract.contract_version_id =
                flattened.contractContract__versions[0].id;
            this.contract.html_content =
                flattened.contractContract__versions[0].htmlContent;
        },
        // Apollo Triggers
        enable_get_contract() {
            this.$apollo.queries.get_contract.setOptions({
                fetchPolicy: "network-only",
            });

            if (!this.$apollo.queries.get_contract.skip) {
                this.$apollo.queries.get_contract.refetch();
            } else {
                this.$apollo.queries.get_contract.skip = false;
                this.$apollo.queries.get_contract.start();
            }
        },
        trigger_get_contract() {
            if (this.contract_id) {
                this.enable_get_contract();
            }
        },
        // Modal Handlers
        handle_ok(bvModalEvent) {
            bvModalEvent.preventDefault();
            this.$refs.form_submit_button.click();
        },

        handle_modal_hide() {
            this.$emit("input", false);
            setTimeout(() => {
                this.form.accepted = false;
            }, 10);
        },

        hide_modal() {
            this.modal.success = true;
            this.modal.loading = false;
            setTimeout(() => {
                this.modal.show = false;
                this.modal.success = false;
                this.$emit("input", false);
                this.modal.loading = false;
            }, 1000);
        },
    },
    mounted() {
        setTimeout(() => {
            if (this.value) {
                this.get_user_location();
            }
        }, 5000);
    },
    watch: {
        value(new_value) {
            this.modal.show = new_value;
            if (new_value && this.apollo_data.get_contract == null) {
                this.$emit("input", false);
            }
            if (new_value) {
                this.trigger_get_contract();
                this.init_scrollbar();
            }
        },

        contract_id() {
            this.trigger_get_contract();
        },
    },
};
</script>

<style>
#contract-container {
    /* background-color: rgb(255, 255, 255); */
    position: relative;
    /* margin: 10px auto; */
    /* padding: 0px; */
    height: 500px;
    overflow-y: scroll;
    overflow-x: hidden;
}
</style>
