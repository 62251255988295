/* TODO: Add a nice success message on login */

<template>
    <div>
        <!-- Header -->
        <div class="header bg-gradient-success py-7 py-lg-8 pt-lg-9">
            <b-container>
                <div class="header-body text-center mb-7">
                    <b-row class="justify-content-center">
                        <b-col xl="5" lg="6" md="8" class="px-5">
                            <h1 class="text-white">Welcome!</h1>
                            <p class="text-lead text-white">
                                Log in to continue your investRand journey
                            </p>
                        </b-col>
                    </b-row>
                </div>
            </b-container>
            <div class="separator separator-bottom separator-skew zindex-100">
                <svg
                    x="0"
                    y="0"
                    viewBox="0 0 2560 100"
                    preserveAspectRatio="none"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <polygon
                        class="fill-default"
                        points="2560 0 2560 100 0 100"
                    ></polygon>
                </svg>
            </div>
        </div>
        <!-- Page content -->
        <b-container class="mt--8 pb-5">
            <b-row class="justify-content-center">
                <b-col lg="5" md="7">
                    <b-card no-body class="bg-secondary border-0 mb-0">
                        <div class="row justify-content-center">
                            <div class="col-6 mt-5">
                                <img
                                    src="img/brand/logos/investRand.svg"
                                    class="img-fluid"
                                />
                            </div>
                        </div>
                        <!-- <b-card-header class="bg-transparent pb-5">
                            <div class="text-muted text-center mt-2 mb-3">
                                <small>Sign in with</small>
                            </div>
                            <div class="btn-wrapper text-center">
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/google.svg"
                                    /></span>
                                    <span class="btn-inner--text">Google</span>
                                </a>
                                <a href="#" class="btn btn-neutral btn-icon">
                                    <span class="btn-inner--icon"
                                        ><img src="img/icons/common/apple.svg"
                                    /></span>
                                    <span class="btn-inner--text">Apple</span>
                                </a>
                            </div>
                        </b-card-header> -->
                        <b-card-body class="px-lg-5 py-lg-5">
                            <!-- <div class="text-center text-muted mb-4">
                                <small>Or sign in with credentials</small>
                            </div> -->
                            <div class="text-center text-muted mb-4">
                                <small>Sign in with credentials</small>
                            </div>
                            <validation-observer
                                v-slot="{ handleSubmit }"
                                ref="formValidator"
                            >
                                <b-form
                                    role="form"
                                    @submit.prevent="handleSubmit(onSubmit)"
                                >
                                    <base-input
                                        autocomplete="username"
                                        alternative
                                        class="mb-3"
                                        name="Email"
                                        :rules="{ required: true, email: true }"
                                        prepend-icon="ni ni-email-83"
                                        placeholder="Email"
                                        v-model="model.email"
                                    >
                                    </base-input>

                                    <base-input
                                        alternative
                                        autocomplete="password"
                                        class="mb-3"
                                        name="Password"
                                        :rules="{ required: true, min: 6 }"
                                        prepend-icon="ni ni-lock-circle-open"
                                        type="password"
                                        placeholder="Password"
                                        v-model="model.password"
                                    >
                                    </base-input>

                                    <div class="text-center">
                                        <div
                                            class="invalid-feedback"
                                            style="display: block"
                                            v-if="error_message"
                                        >
                                            {{ error_message }}
                                        </div>
                                        <base-button
                                            type="primary"
                                            :pill="true"
                                            native-type="submit"
                                            class="my-4"
                                            :loading="isLoading"
                                            >Log in</base-button
                                        >
                                    </div>
                                </b-form>
                            </validation-observer>
                            <b-row class="mt-3">
                                <b-col cols="6">
                                    <router-link to="/forgot_password"
                                        ><small
                                            >Forgot password?</small
                                        ></router-link
                                    >
                                </b-col>
                                <b-col cols="6" class="text-right">
                                    <router-link to="/register"
                                        ><small
                                            >Create new account</small
                                        ></router-link
                                    >
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                email: "",
                password: "",
            },
            isLoading: false,
            error_message: "",
            next: null,
        };
    },
    name: "Login",
    emits: ["updatedLoggedInStatus"],
    methods: {
        onSubmit() {
            this.isLoading = true;
            this.error_message = "";
            user_util
                .login(this.model.email, this.model.password)
                .then((response) => {
                    if (response) {
                        this.isLoading = false;
                        if (this.next != null) {
                            this.$router.push({ path: this.next });
                        } else {
                            this.$router.push({ path: "/" });
                        }
                    } else {
                        this.error_message =
                            "Unable to login with provided credentials, please try again.";
                        this.isLoading = false;
                    }
                })
                .then((user_data) => {
                    this.$emit("updatedLoggedInStatus");
                })
                .catch((err) => {
                    console.log(err);
                    this.error_message =
                        "Unable to login with provided credentials, please try again.";
                    this.isLoading = false;
                });
        },
        get_next() {
            if (this.$route.query.next) {
                this.next = this.$route.query.next;
            }
        },
    },
    mounted() {
        this.get_next();
    },
};
</script>
