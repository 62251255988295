<template>
    <div class="wrapper">
        <notifications></notifications>
        <side-bar logo="img/brand/logos/investRand.png">
            <template slot="links">
                <sidebar-item
                    :link="{
                        name: 'Discover',
                        path: '/listing/discover',
                        icon: 'fas fa-search-dollar text-primary',
                    }"
                ></sidebar-item>

                <sidebar-item
                    :link="{
                        name: 'Service Providers',
                        path: '/sp/discover',
                        icon: 'fas fa-users text-default',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'My Portfolio',
                        path: '/my-portfolio',
                        icon: 'fas fa-seedling text-orange',
                    }"
                />
                <sidebar-item
                    :link="{
                        name: 'Listings',
                        icon: 'ni ni-archive-2 text-orange',
                    }"
                    v-if="
                        menu_items.my_listings.show ||
                        menu_items.listing_manager.show
                    "
                >
                    <sidebar-item
                        :link="{
                            name: 'My Listings',
                            path: '/listing/my-listings',
                            icon: 'ni ni-archive-2 text-orange',
                        }"
                        v-if="menu_items.my_listings.show"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Manage Platform Listings',
                            path: '/listing/manage',
                            icon: 'ni ni-bullet-list-67 text-default',
                        }"
                        v-if="menu_items.listing_manager.show"
                    />
                </sidebar-item>

                <!-- ComponentDev -->
                <!-- <sidebar-item
                    :link="{
                        name: 'ComponentDEV',
                        path: '/componentdev',
                        icon: '',
                    }"
                /> -->

                <!-- <sidebar-item
                    :link="{
                        name: 'Components',
                        icon: 'ni ni-ui-04 text-info',
                    }"
                >
                    <sidebar-item
                        :link="{ name: 'Buttons', path: '/components/buttons' }"
                    />
                    <sidebar-item
                        :link="{ name: 'Cards', path: '/components/cards' }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Grid',
                            path: '/components/grid-system',
                        }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Notifications',
                            path: '/components/notifications',
                        }"
                    />
                    <sidebar-item
                        :link="{ name: 'Icons', path: '/components/icons' }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Typography',
                            path: '/components/typography',
                        }"
                    />

                    <sidebar-item :link="{ name: 'Multi Level' }">
                        <sidebar-item
                            :link="{ name: 'Third level menu', path: '#!' }"
                        />
                        <sidebar-item
                            :link="{ name: 'Just another link', path: '#a' }"
                        />
                        <sidebar-item
                            :link="{ name: 'One last link', path: '#b' }"
                        />
                    </sidebar-item>
                </sidebar-item> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Forms',
                        icon: 'ni ni-single-copy-04 text-pink',
                    }"
                >
                    <sidebar-item
                        :link="{ name: 'Elements', path: '/forms/elements' }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Components',
                            path: '/forms/components',
                        }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Validation',
                            path: '/forms/validation',
                        }"
                    />
                </sidebar-item> -->

                <!-- <sidebar-item
                    :link="{
                        name: 'Tables',
                        icon: 'ni ni-align-left-2 text-default',
                    }"
                >
                    <sidebar-item
                        :link="{ name: 'Tables', path: '/tables/regular' }"
                    />
                    <sidebar-item
                        :link="{ name: 'Sortable', path: '/tables/sortable' }"
                    />
                    <sidebar-item
                        :link="{
                            name: 'Paginated Tables',
                            path: '/tables/paginated',
                        }"
                    />
                </sidebar-item> -->

                <!-- <sidebar-item
                    :link="{
                        name: 'Maps',
                        icon: 'ni ni-map-big text-primary',
                    }"
                >
                    <sidebar-item
                        :link="{ name: 'Google', path: '/maps/google' }"
                    />
                    <sidebar-item
                        :link="{ name: 'Vector', path: '/maps/vector' }"
                    />
                </sidebar-item> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Widgets',
                        icon: 'ni ni-archive-2 text-green',
                        path: '/widgets',
                    }"
                >
                </sidebar-item> -->
                <!-- <sidebar-item
                    :link="{
                        name: 'Charts',
                        icon: 'ni ni-chart-pie-35 text-info',
                        path: '/charts',
                    }"
                >
                </sidebar-item> -->

                <!-- <sidebar-item
                    :link="{
                        name: 'Calendar',
                        icon: 'ni ni-calendar-grid-58 text-red',
                        path: '/calendar',
                    }"
                >
                </sidebar-item> -->
                <sidebar-item
                    :link="{
                        name: 'Settings',
                        icon: 'ni ni-settings text-gray',
                    }"
                >
                    <sidebar-item
                        :link="{ name: 'Profile', path: '/user/profile' }"
                    />
                </sidebar-item>
            </template>

            <!-- <template slot="links-after">
                <hr class="my-3" />
                <h6 class="navbar-heading p-0 text-muted">Documentation</h6>

                <b-nav class="navbar-nav mb-md-3">
                    <b-nav-item
                        href="https://investrand.co.za/learning-lab/bootstrap-vue/quick-start/argon-dashboard"
                    >
                        <i class="ni ni-spaceship"></i>
                        <b-nav-text class="p-0">Getting started</b-nav-text>
                    </b-nav-item>
                    <b-nav-item
                        href="https://investrand.co.za/learning-lab/bootstrap-vue/colors/argon-dashboard"
                    >
                        <i class="ni ni-palette"></i>
                        <b-nav-text class="p-0">Foundation</b-nav-text>
                    </b-nav-item>
                    <b-nav-item
                        href="https://investrand.co.za/learning-lab/bootstrap-vue/avatar/argon-dashboard"
                    >
                        <i class="ni ni-ui-04"></i>
                        <b-nav-text class="p-0">Components</b-nav-text>
                    </b-nav-item>
                    <b-nav-item
                        href="https://investrand.co.za/learning-lab/bootstrap-vue/charts/argon-dashboard"
                    >
                        <i class="ni ni-chart-pie-35"></i>
                        <b-nav-text class="p-0">Plugins</b-nav-text>
                    </b-nav-item>
                </b-nav>
            </template> -->
        </side-bar>
        <div class="main-content">
            <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

            <div
                @click="$sidebar.displaySidebar(false)"
                class="dashboard-content-window"
            >
                <fade-transition
                    :duration="200"
                    origin="center top"
                    mode="out-in"
                >
                    <!-- your content here -->
                    <router-view></router-view>
                </fade-transition>
            </div>
            <content-footer v-if="!$route.meta.hideFooter"></content-footer>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        FadeTransition,
    },
    methods: {
        initScrollbar() {
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                initScrollbar("sidenav");
            }
        },
        check_roles() {
            let user = this.$store.getters.getUser;

            if ("roles" in user && user.roles.length > 0) {
                user.roles.forEach((element) => {
                    if (["ADMIN", "SOURCING_AGENT"].includes(element.name)) {
                        this.menu_items.my_listings.show = true;
                    }
                });
            }
            if ("roles" in user && user.roles.length > 0) {
                user.roles.forEach((element) => {
                    if (["LISTING_MANAGER", "ADMIN"].includes(element.name)) {
                        this.menu_items.listing_manager.show = true;
                    }
                });
            }
        },
        maintain_layout_data() {
            this.session_data_poller = setInterval(() => {
                this.check_roles();
            }, 5000);
        },
    },
    data() {
        return {
            showmodal: false,
            menu_items: {
                my_listings: {
                    show: false,
                },
                listing_manager: {
                    show: false,
                },
            },
            session_data_poller: null,
        };
    },
    mounted() {
        this.initScrollbar();
        this.check_roles();
    },
    created() {
        this.maintain_layout_data();
        user_util.maintain_session_data();
    },
    beforeDestroy() {
        clearInterval(this.session_data_poller);
    },
};
</script>
<style lang="scss">
.dashboard-content-window {
    min-height: 700px;
}
</style>
