/* TODO We need some way to get user ID and account ID from the store. We need
the listing type dropdown. */

<template>
    <div>
        <b-card>
            <!-- Card body -->
            <b-card-body class="p-0">
                <validation-observer
                    v-slot="{ handleSubmit }"
                    ref="formValidator"
                >
                    <b-form @submit.prevent="handleSubmit(onSubmit)">
                        <b-row class="mb-2 justify-content-start">
                            <b-col cols="auto" class="p-1">
                                <router-link
                                    :to="{
                                        path: `/listing/preview/${listing_id}`,
                                    }"
                                    v-if="listing_id"
                                >
                                    <base-button
                                        type="default"
                                        size="sm"
                                        :pill="true"
                                        class="mb-4"
                                    >
                                        Preview
                                    </base-button>
                                </router-link>
                                <router-link
                                    :to="{
                                        path: '/listing/manage-investments',
                                        query: {
                                            lid: listing_id,
                                        },
                                    }"
                                >
                                    <base-button
                                        type="primary"
                                        size="sm"
                                        :pill="true"
                                        class="p-1 mx-1 mb-4"
                                    >
                                        Investment
                                    </base-button>
                                </router-link>
                            </b-col>
                            <b-col cols="auto" class="p-1">
                                <badge
                                    :rounded="true"
                                    size="md"
                                    :type="status_type"
                                    >{{ property.status }}</badge
                                >
                            </b-col>
                        </b-row>

                        <div class="pl-lg-4">
                            <b-row>
                                <b-col lg="6">
                                    <base-input
                                        label="Listing Name*"
                                        class="mb-3"
                                        placeholder="Enter a name for this listing."
                                        name="Listing Name"
                                        :rules="{ required: true }"
                                        :value="property.name"
                                        v-model="property.name"
                                    >
                                    </base-input>
                                </b-col>
                                <b-col lg="6"> </b-col>
                            </b-row>

                            <b-row>
                                <b-col lg="8">
                                    <html-editor
                                        class="mb-3"
                                        label="Description*"
                                        v-model="property.description"
                                        :rules="{ required: true, min: 100 }"
                                        name="Description"
                                    ></html-editor>
                                </b-col>
                                <b-col lg="6"> </b-col>
                            </b-row>

                            <b-row>
                                <b-col lg="8">
                                    <html-editor
                                        class="mb-3"
                                        label="Amenities*"
                                        v-model="property.amenities"
                                        :rules="{ required: true, min: 100 }"
                                        name="Amenities"
                                    ></html-editor>
                                </b-col>
                                <b-col lg="6"> </b-col>
                            </b-row>

                            <b-row>
                                <b-col lg="6">
                                    <base-input
                                        label="Asking Price (R)*"
                                        type="number"
                                        class="mb-3"
                                        placeholder="Asking Price of this property"
                                        name="Asking Price"
                                        :rules="{ required: true }"
                                        :value="property.market_price"
                                        v-model="property.market_price"
                                    >
                                        <!-- v-model="rest_user.first_name" -->
                                    </base-input>
                                </b-col>
                                <b-col lg="6">
                                    <base-input
                                        type="number"
                                        label="Purchase Price (R)"
                                        class="mb-3"
                                        placeholder="Purchase price of this property"
                                        name="Purchase Price"
                                        :rules="{ required: false }"
                                        :value="property.buying_price"
                                        v-model="property.buying_price"
                                    >
                                        <!-- v-model="rest_user.first_name" -->
                                    </base-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col lg="8"
                                    ><validation-provider
                                        :rules="{ required: false }"
                                        name="map"
                                    >
                                        <label
                                            for="map"
                                            class="form-control-label"
                                            >Address*</label
                                        >
                                        <vue-google-autocomplete
                                            ref="address.data"
                                            id="map"
                                            classname="form-control mb-3"
                                            :placeholder="address.placeholder"
                                            v-on:placechanged="getAddressData"
                                        >
                                        </vue-google-autocomplete>
                                        <div
                                            class="invalid-feedback mb-3"
                                            style="display: block"
                                            v-if="address.error_message"
                                        >
                                            {{ address.error_message }}
                                        </div></validation-provider
                                    ></b-col
                                >
                            </b-row>
                            <b-row>
                                <b-col lg="4">
                                    <base-input
                                        label="Listing Type"
                                        :error="type.error_message"
                                        name="Listing Type"
                                        :rules="{ required: true }"
                                    >
                                        <el-select
                                            v-model="type.selection"
                                            filterable
                                            placeholder="Select"
                                            @change="handleTypeSelectionChange"
                                        >
                                            <el-option
                                                v-for="option in type.options"
                                                :key="option.label"
                                                :label="option.label"
                                                :value="option.value"
                                            >
                                            </el-option>
                                        </el-select>
                                        <div
                                            class="invalid-feedback"
                                            style="display: block"
                                            v-if="type.error_message"
                                        >
                                            {{ type.error_message }}
                                        </div>
                                    </base-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <div
                                        class="invalid-feedback"
                                        style="display: block"
                                        v-if="type.error_message"
                                    >
                                        {{ error_message }}
                                    </div>
                                    <base-button
                                        type="primary"
                                        :pill="true"
                                        native-type="submit"
                                        class="my-4"
                                        :loading="isLoading"
                                        :success="show_success"
                                        :disabled="isDisabled"
                                        v-if="mode == 'create'"
                                        >Save</base-button
                                    >
                                    <base-button
                                        type="primary"
                                        :pill="true"
                                        native-type="submit"
                                        class="my-4"
                                        :loading="isLoading"
                                        :success="show_success"
                                        :disabled="isDisabled"
                                        v-if="mode == 'edit'"
                                        >Update</base-button
                                    >
                                </b-col>
                            </b-row>
                        </div>
                    </b-form>
                </validation-observer>

                <!-- <UploadImages
                    @changed="handleImages"
                    :max="5"
                    maxError="Max files exceed"
                    fileError="images files only accepted"
                /> -->
            </b-card-body>
        </b-card>
    </div>
</template>
<script>
import Badge from "@/components/Badge.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import Vue from "vue";
import { Select, Option } from "element-ui";
import LoadScript from "vue-plugin-load-script";
import { GET_ALL_PROPERTY_LISTING_TYPE_OPTIONS } from "@/graphql/queries";
import { GET_PROPERTY_LISTING_BASIC } from "@/graphql/queries";
import { CREATE_PROPERTY_LISTING_ADDRESS } from "@/graphql/mutations";
import { UPDATE_PROPERTY_LISTING_ADDRESS } from "@/graphql/mutations";
import { CREATE_PROPERTY_LISTING_BASIC } from "@/graphql/mutations";
import { UPDATE_PROPERTY_LISTING_BASIC } from "@/graphql/mutations";
import { UPLOAD_ARTIFACT } from "@/graphql/mutations";

import UploadImages from "vue-upload-drop-images";
import HtmlEditor from "@/components/Inputs/HtmlEditor";
Vue.use(LoadScript);
export default {
    name: "ListingBasicDetail",
    components: {
        VueGoogleAutocomplete,
        [Select.name]: Select,
        [Option.name]: Option,
        HtmlEditor,
        UploadImages,
        Badge
    },
    apollo: {
        allPropertyListingType: {
            query: GET_ALL_PROPERTY_LISTING_TYPE_OPTIONS,
            result() {
                this.handleAllPropertyListingType();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
        },
        get_property_listing: {
            query: GET_PROPERTY_LISTING_BASIC,
            result(data) {
                this.handle_get_property_listing(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            update(data) {
                this.apollo_data.get_property_listing = data;
            },
            skip: true,
            variables() {
                return {
                    listing_id: this.listing_id,
                };
            },
        },
    },
    emits: ["created-listing-id"],
    props: {
        mode: {
            type: String,
            default: "create",
            description: "Mode of form (create|update)",
        },
        listing_id: {
            type: String,
            default: "",
            description: "The id of the listing in question.",
        },
    },
    data() {
        return {
            local_mode: "create",
            local_listing_id: "",
            errors: [],
            property: {
                name: "",
                market_price: "",
                buying_price: "",
                description: "",
                amenities: "",
            },
            address: {
                data: {
                    // street_number: "",
                    // route: "",
                    // postal_code: "",
                    // country: "",
                    placeResultData: {
                        place_id: "",
                        formatted_address: "",
                    },
                    // latitude: "",
                    // longitude: "",
                    // administrative_area_level_2: "",
                },
                error_message: "",
                id: "",
                placeholder: "Please type your address as you see it on google",
                received_place_id: "",
            },
            type: {
                options: [],
                selection: "",
                error_message: "",
            },
            allPropertyListingType: [],
            isLoading: false,
            isDisabled: false,
            error_message: "",
            show_success: false,
            is_non_multi_unit: false,

            apollo_data: {
                get_property_listing: "",
            },
        };
    },

    methods: {
        /**
         * When the location found
         * @param {Object} addressData Data of the found location
         * @param {Object} placeResultData PlaceResult object
         * @param {String} id Input container ID
         */
        handleImages(files) {
            this.uploadFile(files);
        },

        uploadFile(files) {
            if (!files.length) {
                return;
            }

            rest_api.property.uploadFile(files[0]);
            // let response = this.$apollo.mutate({
            //     mutation: UPLOAD_ARTIFACT,
            //     variables: {
            //         file: files[0],
            //     },
            //     context: {
            //         hasUpload: true,
            //     },
            // });
        },

        getAddressData: function (addressData, placeResultData, id) {
            this.address.data = addressData;
            this.address.data["placeResultData"] = placeResultData;
            this.address.data["id"] = id;
        },
        handleAllPropertyListingType() {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(this.allPropertyListingType)
            );
            let listing_type_options = [];
            flattened.forEach(function (arrayItem) {
                listing_type_options.push({
                    label: arrayItem.name,
                    value: arrayItem.id,
                });
            });
            this.type.options = listing_type_options;
        },
        getNameFromSelectionValue(value) {
            let val = "";
            this.type.options.forEach((element) => {
                if (value == element.value) {
                    val = element.label;
                }
            });
            return val;
        },
        handleTypeSelectionChange() {
            let single_unit_types = [
                "Free Standing Unit",
                "Free Standing House",
                "Apartment",
                "Duplex",
                "Attached Unit",
            ];
            if (
                single_unit_types.includes(
                    this.getNameFromSelectionValue(this.type.selection)
                )
            ) {
                this.is_non_multi_unit = true;
            }
        },
        createAddress() {
            //Creates a new address. Returns the address id.
            return new Promise((resolve, reject) => {
                this.address.error_message = "";
                if (this.address.data === "") {
                    this.address.error_message = "Please specify an address.";
                    reject(new Error("Address is not specified."));
                    return;
                }
                let error_messages = [];
                let variables = {
                    countryCode: "",
                    gPlaceId: "",
                    gAddress: "",
                    gpsLong: "",
                    gpsLat: "",
                    streetNumber: "",
                    streetName: "",
                    cityName: "",
                    postalCode: "",
                    province: "",
                };

                if (
                    !("street_number" in this.address.data) ||
                    this.address.data.street_number == ""
                ) {
                    error_messages.push("'a street number'");
                } else {
                    variables.streetNumber = this.address.data.street_number;
                }
                if (
                    !("route" in this.address.data) ||
                    this.address.data.route == ""
                ) {
                    error_messages.push("'a street name'");
                } else {
                    variables.streetName = this.address.data.route;
                }

                if ("postal_code" in this.address.data) {
                    variables.postalCode = this.address.data.postal_code;
                }
                if ("country" in this.address.data) {
                    variables.countryCode = this.address.data.country;
                }
                if (
                    "placeResultData" in this.address.data &&
                    "place_id" in this.address.data.placeResultData
                ) {
                    variables.gPlaceId =
                        this.address.data.placeResultData.place_id;
                }

                if (
                    "placeResultData" in this.address.data &&
                    "formatted_address" in this.address.data.placeResultData
                ) {
                    variables.gAddress =
                        this.address.data.placeResultData.formatted_address;
                }
                if ("latitude" in this.address.data) {
                    variables.gpsLat = this.address.data.latitude;
                }
                if ("longitude" in this.address.data) {
                    variables.gpsLong = this.address.data.longitude;
                }
                if ("administrative_area_level_1" in this.address.data) {
                    variables.province =
                        this.address.data.administrative_area_level_1;
                }
                if ("locality" in this.address.data) {
                    variables.cityName = this.address.data.locality;
                } else if ("administrative_area_level_2" in this.address.data) {
                    variables.cityName =
                        this.address.data.administrative_area_level_2;
                } else {
                    error_messages.push("'a city/town name'");
                }

                if (error_messages.length > 0) {
                    this.address.error_message =
                        "An address should include: " +
                        error_messages.join(", ");
                    reject(new Error(error_messages.join(", ")));
                    return;
                }

                return this.$apollo
                    .mutate({
                        mutation: CREATE_PROPERTY_LISTING_ADDRESS,
                        variables: variables,
                    })
                    .then((res) => {
                        if ("data" in res) {
                            if ("propertyCreateListingAddress" in res.data) {
                                this.address.id =
                                    res.data.propertyCreateListingAddress.listingAddress.id;
                                resolve(true);
                                return;
                            }
                        }
                    })
                    .catch((errors) => {
                        console.log(
                            "Query Error: MUTATE_USER_PROFILE_CONTACT_DETAIL"
                        );
                        console.log(errors);
                        console.log(errors.graphQLErrors);
                        reject(errors);
                    });
            });
        },
        createListing() {
            return new Promise((resolve) => {
                return setTimeout(() => {
                    let variables = {
                        name: this.property.name,
                        listerUser: store.getters.getUserIdB64,
                        listerAccount: store.getters.getAccountIdB64,
                        address: this.address.id,
                        listingType: this.type.selection,
                        marketPriceAmount: this.property.market_price,
                        amenities: this.property.amenities,
                        description: this.property.description,
                    };
                    if (this.property.buying_price) {
                        variables.buyingPriceAmount =
                            this.property.buying_price;
                    }

                    return this.$apollo
                        .mutate({
                            mutation: CREATE_PROPERTY_LISTING_BASIC,
                            variables: variables,
                            // errorPolicy: "all",
                        })
                        .then((res) => {
                            if (
                                "data" in res &&
                                "propertyCreateListing" in res.data
                            ) {
                                this.$emit(
                                    "created-listing-id",
                                    res.data.propertyCreateListing.listing.id
                                );

                                resolve(true);
                                return;
                            } else {
                                resolve(false);
                                return;
                            }
                        })
                        .catch((errors) => {
                            console.log(
                                "Query Error: CREATE_PROPERTY_LISTING_BASIC"
                            );
                            console.log(errors);
                            console.log(errors.graphQLErrors);
                            resolve(false);
                            return;
                        });
                }, 1000);
            });
        },
        createNewListing() {
            return this.createAddress()
                .then((res) => {
                    if (!res) {
                        this.error_message =
                            "Something went wrong, please try again later";
                        return;
                    }
                })
                .then((res) => {
                    this.createListing().then((res) => {
                        this.isLoading = false;
                        if (!res) {
                            this.error_message =
                                "Something went wrong, please try again later";
                            return;
                        }
                        global_event_emitter.$emit("listings_update");
                        this.show_success = true;
                        this.isDisabled = true;
                        setTimeout(() => {
                            this.show_success = false;
                            this.isDisabled = false;
                        }, 1000);
                    });
                })
                .catch((res) => {
                    this.isLoading = false;
                    this.isDisabled = false;
                });
        },
        handle_get_property_listing(data) {
            this.property = {
                id: data.data.propertyListing.id,
                name: data.data.propertyListing.name,
                market_price: data.data.propertyListing.marketPrice.amount,
                buying_price: data.data.propertyListing.buyingPrice.amount,
                description: data.data.propertyListing.description,
                amenities: data.data.propertyListing.amenities,
                status: data.data.propertyListing.status.friendlyName,
            };
            this.address.id = data.data.propertyListing.address.id;
            this.address.placeholder =
                data.data.propertyListing.address.gAddress;
            this.address.data.placeResultData.place_id =
                data.data.propertyListing.address.gPlaceId;
            this.address.received_place_id =
                data.data.propertyListing.address.gPlaceId;

            this.type.selection = data.data.propertyListing.listingType.id;
        },
        get_property_listing() {
            if (this.property.name == "") {
                if (!this.$apollo.queries.get_property_listing.skip) {
                    this.$apollo.queries.get_property_listing.refetch();
                } else {
                    this.$apollo.queries.get_property_listing.skip = false;
                    this.$apollo.queries.get_property_listing.start();
                }
            }
        },

        update_property_listing() {
            let variables = {
                listing_id: this.listing_id,
                name: this.property.name,
                listerUser: store.getters.getUserIdB64,
                listerAccount: store.getters.getAccountIdB64,
                address: this.address.id,
                listingType: this.type.selection,
                marketPriceAmount: this.property.market_price,
                amenities: this.property.amenities,
                description: this.property.description,
            };
            if (this.property.buying_price) {
                variables.buyingPriceAmount = this.property.buying_price;
            }

            return this.$apollo
                .mutate({
                    mutation: UPDATE_PROPERTY_LISTING_BASIC,
                    variables: variables,
                    // errorPolicy: "all",
                })
                .then((res) => {
                    if ("data" in res && "propertyUpdateListing" in res.data) {
                        this.show_success = true;
                        this.isDisabled = true;
                        this.isLoading = false;
                        global_event_emitter.$emit("listings_update");
                        setTimeout(() => {
                            this.show_success = false;
                            this.isDisabled = false;
                        }, 1000);
                        return;
                    } else {
                        this.isLoading = false;
                        this.show_success = false;
                        this.isDisabled = false;
                        return;
                    }
                })
                .catch((errors) => {
                    console.log("Query Error: UPDATE_PROPERTY_LISTING_BASIC");
                    console.log(errors);
                    console.log(errors.graphQLErrors);
                    return;
                });
        },

        update_property_listing_address() {
            return new Promise((resolve, reject) => {
                // Checking if address has changed
                if (
                    this.address.received_place_id ==
                    this.address.data.placeResultData.place_id
                ) {
                    resolve(true);
                } else {
                    this.address.error_message = "";
                    if (this.address.data === "") {
                        this.address.error_message =
                            "Please specify an address.";
                        reject(new Error("Address is not specified."));
                        return;
                    }
                    let error_messages = [];
                    let variables = {
                        address_id: this.address.id,
                        countryCode: "",
                        gPlaceId: "",
                        gAddress: "",
                        gpsLong: "",
                        gpsLat: "",
                        streetNumber: "",
                        streetName: "",
                        cityName: "",
                        postalCode: "",
                        province: "",
                    };

                    if (
                        !("street_number" in this.address.data) ||
                        this.address.data.street_number == ""
                    ) {
                        error_messages.push("'a street number'");
                    } else {
                        variables.streetNumber =
                            this.address.data.street_number;
                    }
                    if (
                        !("route" in this.address.data) ||
                        this.address.data.route == ""
                    ) {
                        error_messages.push("'a street name'");
                    } else {
                        variables.streetName = this.address.data.route;
                    }

                    if ("postal_code" in this.address.data) {
                        variables.postalCode = this.address.data.postal_code;
                    }
                    if ("country" in this.address.data) {
                        variables.countryCode = this.address.data.country;
                    }
                    if (
                        "placeResultData" in this.address.data &&
                        "place_id" in this.address.data.placeResultData
                    ) {
                        variables.gPlaceId =
                            this.address.data.placeResultData.place_id;
                    }

                    if (
                        "placeResultData" in this.address.data &&
                        "formatted_address" in this.address.data.placeResultData
                    ) {
                        variables.gAddress =
                            this.address.data.placeResultData.formatted_address;
                    }
                    if ("latitude" in this.address.data) {
                        variables.gpsLat = this.address.data.latitude;
                    }
                    if ("longitude" in this.address.data) {
                        variables.gpsLong = this.address.data.longitude;
                    }
                    if ("administrative_area_level_1" in this.address.data) {
                        variables.province =
                            this.address.data.administrative_area_level_1;
                    }
                    if ("locality" in this.address.data) {
                        variables.cityName = this.address.data.locality;
                    } else if (
                        "administrative_area_level_2" in this.address.data
                    ) {
                        variables.cityName =
                            this.address.data.administrative_area_level_2;
                    } else {
                        error_messages.push("'a city/town name'");
                    }

                    if (error_messages.length > 0) {
                        this.address.error_message =
                            "An address should include: " +
                            error_messages.join(", ");
                        reject(new Error(error_messages.join(", ")));
                        return;
                    }

                    return this.$apollo
                        .mutate({
                            mutation: UPDATE_PROPERTY_LISTING_ADDRESS,
                            variables: variables,
                        })
                        .then((res) => {
                            if ("data" in res) {
                                if (
                                    "propertyUpdateListingAddress" in res.data
                                ) {
                                    this.address.id =
                                        res.data.propertyUpdateListingAddress.listingAddress.id;
                                    resolve(true);
                                    return;
                                }
                            }
                        })
                        .catch((errors) => {
                            console.log(
                                "Query Error: MUTATE_USER_PROFILE_CONTACT_DETAIL"
                            );
                            console.log(errors);
                            console.log(errors.graphQLErrors);
                            reject(errors);
                        });
                }
            });
        },

        onSubmit() {
            this.isLoading = true;
            if (this.listing_id == "") {
                this.createNewListing();
            } else if (this.mode == "edit" && this.listing_id != "") {
                this.update_property_listing_address().then(() => {
                    this.update_property_listing();
                });
            }
        },
    },
    mounted() {
        if (this.mode == "edit" && this.listing_id != "") {
            // Get the data for the item.
            this.get_property_listing();
        }
    },
    beforeCreate() {
        Vue.loadScript(
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyCmKK8RSy2IysMH6exrjnokna_Bes1FPNU&libraries=places"
        );
    },
    watch: {},
    computed: {
        status_type() {
            if (this.property.status == "Published") {
                return "info";
            } else {
                return "warning";
            }
        },
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
.text-area {
    border-radius: 1rem;
}
</style>
