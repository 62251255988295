<template>
    <div>
        <div class="header pb-6 d-flex align-items-center">
            <!-- Mask -->
            <span class="mask bg-white opacity-8"></span>
            <!-- Header container -->
            <b-container fluid class="d-flex align-items-center">
                <b-row>
                    <b-col lg="12">
                        <h1 class="display-1 text-orange pb-0 mb-0">
                            Manage Listings
                        </h1>
                        <p class="text-default bold mt-0 mb-5 font-weight-bold">
                            Let's make sure we have quality on the platform!
                        </p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <b-container class="mt--6">
            <b-row class="mb-4" v-if="property_listings.length != 0">
                <b-col sm="11"></b-col>

                <!-- Search -->
                <b-col sm="3">
                    <b-form
                        class="navbar-search form-inline mr-sm-3 navbar-search-light"
                        id="navbar-search-main"
                        @submit.prevent="on_search_submit"
                    >
                        <b-form-group class="mb-0">
                            <b-input-group
                                class="input-group-alternative input-group-merge"
                            >
                                <div class="input-group-prepend">
                                    <span class="input-group-text"
                                        ><i class="fas fa-search"></i
                                    ></span>
                                </div>
                                <b-form-input
                                    placeholder="Search"
                                    type="text"
                                    v-model="search.search_term"
                                    @keyup="on_keyup_search"
                                >
                                </b-form-input>

                                <input
                                    type="submit"
                                    ref="search_form_submit"
                                    hidden
                                />
                            </b-input-group>
                        </b-form-group>

                        <button
                            type="button"
                            class="close"
                            data-action="search-close"
                            data-target="#navbar-search-main"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </b-form>
                </b-col>
                <b-col>
                    <Transition>
                        <i
                            v-if="search.is_loading"
                            class="fas fa-spinner fa-spin ml--3 mt-3 text-muted"
                        ></i>
                    </Transition>
                </b-col>
            </b-row>
            <b-row v-if="property_listings.length == 0 && !$apollo.loading">
                <b-col class="text-center">
                    <h3 class="text-default">No listings found to manage</h3>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12">
                    <h1 class="text-center">
                        <i
                            v-if="$apollo.loading"
                            class="fas fa-spinner fa-spin"
                        ></i>
                    </h1>
                </b-col>
                <b-col sm="12">
                    <div
                        v-for="property_listing in filtered_property_listings"
                        :key="property_listing.id"
                    >
                        <transition>
                            <listing-manage-item
                                :id="property_listing.id"
                                :name="property_listing.name"
                                :creator_first_name="
                                    property_listing.creator.first_name
                                "
                                :creator_last_name="
                                    property_listing.creator.last_name
                                "
                                :address="property_listing.address"
                                :cashflow="property_listing.cashflow"
                                :buying_price="property_listing.buying_price"
                                :bathrooms="property_listing.bathrooms"
                                :bedrooms="property_listing.bedrooms"
                                :tags="property_listing.tags"
                                :type="property_listing.type"
                                :status="property_listing.status"
                                :approval_status="property_listing.approval_status"
                            ></listing-manage-item>
                        </transition>
                    </div>
                    <h3
                        v-if="
                            filtered_property_listings.length === 0 &&
                            search.search_term !== ''
                        "
                        class="text-muted text-center"
                    >
                        Nothing found
                    </h3>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
<script>
import { debounce } from "debounce";

import ListingManageItem from "@/views/Components/Listing/ListingManageItem.vue";

// Queries
import { GET_ALL_PROPERTY_LISTING_MANAGE_VIEW } from "@/graphql/queries";
export default {
    name: "MyListings",
    components: {
        ListingManageItem,
    },
    apollo: {
        allPropertyListing: {
            query: GET_ALL_PROPERTY_LISTING_MANAGE_VIEW,
            result(data) {
                this.handleAllPropertyListing(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },

            update(data) {
                this.property_listings = data;
            },
        },
    },

    data() {
        return {
            allPropertyListing: "",
            property_listings: [],
            filtered_property_listings: [],

            search: {
                search_term: "",
                is_loading: false,
            },
        };
    },
    methods: {
        // Apollo Handlers
        handleAllPropertyListing(data) {
            if (!data.data.allPropertyListing) {
                return;
            }
            let flattened = graph_utils.flatten_relay_response(
                data.data.allPropertyListing
            );
            this.property_listings = [];
            flattened.forEach((element) => {
                let listing = {
                    id: element.id,
                    name: element.name,
                    creator: {
                        first_name: element.listerUser__firstName,
                        last_name: element.listerUser__lastName,
                        username: element.listerUser__username,
                    },
                    address: element.address__gAddress,
                    buying_price: {
                        symbol: element.buyingPrice__currency_symbol || "R",
                        amount: element.buyingPrice__amount,
                    },
                    cashflow: {
                        symbol:
                            element.totalExpectedRentalIncome__currency_symbol ||
                            "R",
                        amount: element.totalExpectedRentalIncome__amount,
                    },
                    bathrooms: 0,
                    bedrooms: 0,
                    tags: [],
                    type: element.listingType__name,
                    status: element.status__friendlyName,
                    approval_status: {
                        id: "",
                        friendly_name: "",
                        name: "",
                    },
                };

                if ("approval__id" in element) {
                    listing.approval_status = {
                        id: element.approval__id,
                        friendly_name: element.approval__state__friendlyName,
                        name: element.approval__state__name,
                    };
                }

                this.property_listings.push(listing);
            });

            this.filtered_property_listings = this.property_listings;
            this.search_term = "";
        },

        // Search Functionality

        on_keyup_search() {
            this.search.is_loading = true;
            this.debounced_on_keyup_search();
        },

        debounced_on_keyup_search: debounce(function () {
            this.$refs.search_form_submit.click();
        }, 1000),
        on_search_submit() {
            if (this.search.search_term === "") {
                this.filtered_property_listings = this.property_listings;
                setTimeout(() => {
                    this.search.is_loading = false;
                }, 500);
            } else {
                this.filtered_property_listings = this.filter_listings(
                    this.search.search_term,
                    this.property_listings
                );
                this.search.is_loading = false;
            }
        },

        filter_listings(search_term, in_array) {
            let out_array = in_array.filter((item) => {
                return (
                    item.name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.address
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.creator.username
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.creator.first_name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.creator.last_name
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) ||
                    item.status
                        .toLowerCase()
                        .includes(search_term.toLowerCase()) || 
                        item.approval_status.friendly_name
                        .toLowerCase()
                        .includes(search_term.toLowerCase())
                );
            });
            return out_array;
        },
    },
    mounted() {
        this.$apollo.queries.allPropertyListing.setOptions({
            fetchPolicy: "network-only",
        });
        this.$apollo.queries.allPropertyListing.refetch();
    },
    created() {
        global_event_emitter.$on("listings_update", () => {
            this.$apollo.queries.allPropertyListing.refetch();
        });
    },
    beforeDestroy() {
        global_event_emitter.$off("listings_update");
    },
    
};
</script>
<style>
.profile-header {
    background-image: url(/img/theme/profile-cover.jpg);
    background-size: cover;
    background-position: center top;
    min-height: 500px;
}
</style>
