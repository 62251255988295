<template>
    <card>
        <b-row align-v="center" slot="header">
            <b-col cols="8">
                <h3 class="mb-0">Edit profile</h3>
            </b-col>
            <!-- <b-col cols="4" class="text-right">
        <a href="#!" class="btn btn-sm btn-primary">Settings</a>
      </b-col> -->
        </b-row>
        <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
            <b-form @submit.prevent="handleSubmit(onSubmit)">
                <h6 class="heading-small text-muted mb-4">User information</h6>

                <div class="pl-lg-4">
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="First Name"
                                class="mb-3"
                                prepend-icon="ni ni-circle-08"
                                :placeholder="
                                    rest_user.first_name
                                        ? rest_user.first_name
                                        : 'First Name'
                                "
                                name="Name"
                                :rules="{ required: true }"
                                v-model="rest_user.first_name"
                                :value="
                                    rest_user.first_name
                                        ? rest_user.first_name
                                        : ''
                                "
                            >
                            </base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input
                                label="Last Name"
                                class="mb-3"
                                prepend-icon="ni ni-circle-08"
                                :placeholder="
                                    rest_user.last_name
                                        ? rest_user.last_name
                                        : 'Last Name'
                                "
                                name="Name"
                                :rules="{ required: true }"
                                v-model="rest_user.last_name"
                                :value="
                                    rest_user.last_name
                                        ? rest_user.last_name
                                        : ''
                                "
                            >
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="Email"
                                class="mb-3"
                                name="Email"
                                :rules="{ required: true, email: true }"
                                prepend-icon="ni ni-email-83"
                                :placeholder="
                                    rest_user.email ? rest_user.email : 'Email'
                                "
                                v-model="rest_user.email"
                                :value="rest_user.email ? rest_user.email : ''"
                            ></base-input
                        ></b-col>
                    </b-row>
                </div>
                <hr class="my-4" />

                <!-- Contact information -->
                <h6 class="heading-small text-muted mb-4">
                    Contact information
                </h6>

                <div class="pl-lg-4">
                    <b-row>
                        <b-col lg="6">
                            <base-input
                                label="Cell number"
                                class="mb-3"
                                name="CellNumber"
                                :rules="{ required: true, digits: 10 }"
                                prepend-icon="fa fa-phone"
                                :placeholder="
                                    gql_user.phone_number
                                        ? gql_user.phone_number
                                        : 'Cell Number'
                                "
                                v-model="gql_user.phone_number"
                                :value="
                                    gql_user.phone_number
                                        ? gql_user.phone_number
                                        : ''
                                "
                            ></base-input>
                        </b-col>
                        <b-col lg="6">
                            <base-input
                                label="Backup number"
                                class="mb-3"
                                name="secondaryNumber"
                                :rules="{ required: false, digits: 10 }"
                                prepend-icon="fa fa-phone"
                                :placeholder="
                                    gql_user.secondary_phone_number
                                        ? gql_user.secondary_phone_number
                                        : 'Backup Number'
                                "
                                v-model="gql_user.cell_number"
                                :value="
                                    gql_user.secondary_phone_number
                                        ? gql_user.secondary_phone_number
                                        : ''
                                "
                            ></base-input>
                        </b-col>
                    </b-row>
                </div>
                <hr class="mb-0" />
                <b-row class="text-right mt-0"
                    ><b-col>
                        <div
                            class="invalid-feedback"
                            style="display: block"
                            v-if="error_message"
                        >
                            {{ error_message }}
                        </div>
                        <base-button
                            type="default"
                            :pill="true"
                            native-type="submit"
                            class="my-4"
                            :loading="is_loading"
                            :success="show_success"
                            >Save</base-button
                        >
                    </b-col></b-row
                >
            </b-form>
        </validation-observer>
    </card>
</template>
<script>
import { GET_USER_PROFILE_CONTACT_DETAIL } from "@/graphql/queries";
import { MUTATE_USER_PROFILE_CONTACT_DETAIL } from "@/graphql/mutations";
import { resolve } from "path";
import { GlobalEventEmitter } from "@/util/GlobalEventEmitter";

export default {
    name: "EditProfileForm",
    apollo: {
        allUserProfileContactDetail: {
            query: GET_USER_PROFILE_CONTACT_DETAIL,
            result() {
                this.handleAllUserProfileContactDetail();
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors.graphQLErrors);
                return false;
            },
            variables() {
                return {
                    b64_user_id: this.rest_user.id_b64,
                };
            },
            skip: true,
        },
    },
    data() {
        return {
            allUserProfileContactDetail: [],
            rest_user: {
                email: "",
                first_name: "",
                last_name: "",
                id: 0,
                id_b64: "",
            },
            gql_user: {
                phone_number: "",
                secondary_phone_number: "",
            },
            is_loading: false,
            error_message: "",
            show_success: false,
        };
    },
    methods: {
        onSubmit() {
            this.is_loading = true;
            let data = {
                first_name: this.rest_user.first_name,
                last_name: this.rest_user.last_name,
                email: this.rest_user.email,
            };
            rest_api.user
                .update(data)
                .catch(() => {
                    this.error_message =
                        "Could not update your profile, please try again later.";
                    this.is_loading = false;
                })
                .then(() => {
                    return this.updateUserProfileContactDetail();
                })
                .then(() => {
                    global_event_emitter.$emit("profile_update");
                    this.is_loading = false;
                    this.show_success = true;
                    setTimeout(() => {
                        this.show_success = false;
                    }, 1000);
                });
        },
        updateUserProfileContactDetail() {
            let variables = {
                phoneNumber: this.gql_user.phone_number,
            };
            if (this.gql_user.secondary_phone_number) {
                variables["secondaryPhoneNumber"] =
                    this.gql_user.secondary_phone_number;
            }
            return this.$apollo.mutate({
                mutation: MUTATE_USER_PROFILE_CONTACT_DETAIL,
                variables: variables,
            });
        },
        handleAllUserProfileContactDetail() {
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(
                    this.allUserProfileContactDetail
                )
            );

            if (!flattened) {
                return;
            }
            this.gql_user.phone_number = flattened[0].phoneNumber;
            this.gql_user.secondary_phone_number =
                flattened[0].secondaryPhoneNumber;
        },
        callApi() {
            this.$apollo.queries.allUserProfileContactDetail.setOptions({
                fetchPolicy: "network-only",
            });
            if (!this.$apollo.queries.allUserProfileContactDetail.skip) {
                this.$apollo.queries.allUserProfileContactDetail.refetch();
            } else {
                this.$apollo.queries.allUserProfileContactDetail.skip = false;
                this.$apollo.queries.allUserProfileContactDetail.start();
            }
        },
        loadUserData() {
            new Promise((res, rej) => {
                this.rest_user = utils.deepcopy(this.$store.getters.getUser);
                this.rest_user.id_b64 = Buffer(
                    "User:" + String(this.rest_user.id)
                ).toString("base64");
                if (this.rest_user.id_b64) {
                    resolve();
                }
            }).then(this.callApi());
        },
    },
    created() {
        this.loadUserData();
    },
};
</script>
<style></style>
