<template>
    <div>
        <b-col sm="12" md="12" lg="12">
            <h2>Monthly Management</h2>
        </b-col>
        <b-row>
            <b-col sm="12" md="12" lg="12">
                <b-card>
                    <!-- Card body -->

                    <b-card-body class="p-0">
                        <!-- Income -->
                        <b-row>
                            <b-col>
                                <h5>
                                    Income
                                    <Transition>
                                        <i
                                            v-if="$apollo.loading"
                                            class="fas fa-spinner fa-spin"
                                        ></i>
                                    </Transition>
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <h2 class="text-default">Rental Income*</h2>
                            </b-col>
                            <b-col class="text-right pr-md-5">
                                <h2 class="text-default">
                                    {{ total_rental_income.symbol }}&nbsp;{{
                                        formatted_currency(
                                            total_rental_income.amount
                                        )
                                    }}
                                </h2>
                            </b-col>
                        </b-row>
                        <b-row class="mt-3">
                            <b-col>
                                <h5>
                                    Expenses
                                    <Transition>
                                        <i
                                            v-if="$apollo.loading"
                                            class="fas fa-spinner fa-spin"
                                        ></i>
                                    </Transition>
                                </h5>
                            </b-col>
                        </b-row>
                        <b-row
                            v-for="recurring_expense in recurring_expenses"
                            :key="recurring_expense.id"
                        >
                            <b-col>
                                <h2 class="text-default">
                                    {{ recurring_expense.description }}*
                                </h2>
                            </b-col>
                            <b-col class="text-right pr-md-5">
                                <h2 class="text-default text-nowrap">
                                    {{
                                        recurring_expense.cost__currency__symbol
                                    }}&nbsp;{{
                                        formatted_currency(
                                            recurring_expense.cost__amount
                                        )
                                    }}
                                </h2>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col xs="12">
                                <hr class="my-1" />
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                <h2 class="text-orange">Monthly Cashflow*</h2>
                            </b-col>
                            <b-col class="text-right pr-md-5">
                                <h2 class="text-orange text-nowrap">
                                    R
                                    {{
                                        formatted_currency(get_total_cashflow())
                                    }}
                                </h2>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                <h1 class="text-primary">Annual Cashflow*</h1>
                                <h6
                                    v-if="total_rental_months < 12"
                                    class="small"
                                >
                                    As this is student acommodation, it is
                                    rented out {{ total_rental_months }} months
                                    out of every year.
                                </h6>
                            </b-col>
                            <b-col class="text-right pr-md-5">
                                <h1 class="text-primary text-nowrap">
                                    R
                                    {{
                                        formatted_currency(
                                            get_total_cashflow() *
                                                total_rental_months
                                        )
                                    }}
                                </h1>
                            </b-col>
                        </b-row>
                        <b-row class="mt-4">
                            <b-col>
                                <h1 class="text-orange">Annual ROI*</h1>
                                <h6 class="small">
                                    ROI is calculated using the total buying
                                    costs and annual cashflow.
                                </h6>
                            </b-col>
                            <b-col class="text-right pr-md-5">
                                <h1 class="text-orange text-nowrap">
                                    {{ formatted_currency(get_roi()) }}%
                                </h1>
                            </b-col>
                        </b-row>

                        <div v-if="get_gross_annual_yield() > 0">
                            <b-row>
                                <b-col xs="12">
                                    <hr class="my-1" />
                                </b-col>
                            </b-row>
                            <b-row class="mt-4">
                                <b-col>
                                    <h1 class="text-default">
                                        Gross Annual Yield*
                                    </h1>
                                </b-col>
                                <b-col class="text-right pr-md-5">
                                    <h1 class="text-default text-nowrap">
                                        {{
                                            formatted_currency(
                                                get_gross_annual_yield()
                                            )
                                        }}%
                                    </h1>
                                </b-col>
                            </b-row>
                        </div>

                        <b-row>
                            <b-col class="pt-4">
                                <h6 class="small">
                                    *Amounts shown are estimates calculated on
                                    available information.
                                </h6>
                            </b-col>
                        </b-row>
                    </b-card-body>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
// Component imports

export default {
    name: "ListingMonthlyPnl",
    components: {},

    props: {
        total_rental_income: {
            type: Object,
            description: "Total Rental income for the given listing.",
            default() {
                return {
                    amount: 0,
                    symbol: "R",
                };
            },
        },
        recurring_expenses: {
            type: Array,
            description: "Array of monthly expenses",
            default() {
                return [
                    {
                        // id: "UmVjdXJyaW5nRXhwZW5zZU5vZGU6NTg=",
                        // description: "Property Management",
                        // cost__amount: 500,
                        // cost__currency__symbol: "R",
                    },
                ];
            },
        },
        buying_price: {
            type: Object,
            description: "Purchase price of the property",
            default() {
                return {
                    amount: 0,
                    symbol: "",
                };
            },
        },
        total_rental_months: {
            type: Number,
            description:
                "The number of months that a property is rented out for out of a givn year.",
            default: 10,
        },
        total_bying_costs: {
            type: Object,
            description: "The amount of money spent buying this money in cash.",
            default() {
                return {
                    amount: 30000, //null,
                    symbol: "R", //null,
                };
            },
        },
    },

    data() {
        return {
            total_cashflow: {
                symbol: "R",
                amount: 5000,
            },
        };
    },
    methods: {
        formatted_currency(value) {
            return value.toLocaleString();
        },
        get_total_cashflow() {
            let cashflow = this.total_rental_income.amount;
            if (this.recurring_expenses.length > 0) {
                this.recurring_expenses.forEach((recurring_expense) => {
                    cashflow = cashflow - recurring_expense.cost__amount;
                });
            }
            return cashflow;
        },
        get_gross_annual_yield() {
            return (
                Math.round(
                    ((this.get_total_cashflow() * this.total_rental_months) /
                        this.buying_price.amount) *
                        100 *
                        100
                ) / 100
            );
        },

        get_roi() {
            return (
                Math.round(
                    ((this.get_total_cashflow() * this.total_rental_months) /
                        this.total_bying_costs.amount) *
                        100 *
                        100
                ) / 100
            );
        },
    },
    watch: {},
};
</script>
<style></style>
