<template>
    <div>
        <b-modal
            v-model="modal.show"
            size="sm"
            id="cru-buying-cost-modal"
            :title="
                buying_cost_id === ''
                    ? 'Create Buying Cost'
                    : 'Edit Buying Cost'
            "
            ok-title="Save"
            @hidden="handle_modal_hide"
            @ok="handle_ok"
            class="p-0"
        >
            <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
                <b-form @submit.prevent="handleSubmit(on_submit)">
                    <input
                        type="submit"
                        ref="expense_form_submit_button"
                        hidden
                    />
                    <b-row>
                        <b-col>
                            <!--             -->
                            <!-- :error="form.rental_approach.error_message" -->
                            <base-input
                                label="Buying Cost Type"
                                name="Buying Cost Type"
                                :rules="{ required: true }"
                            >
                                <el-select
                                    v-model="
                                        form.transaction_cost_type.selection
                                    "
                                    filterable
                                    placeholder="Select"
                                    @change="input_on_key_up_debounced"
                                >
                                    <el-option
                                        v-for="option in form
                                            .transaction_cost_type.options"
                                        :key="option.label"
                                        :label="option.label"
                                        :value="option.value"
                                    >
                                    </el-option>
                                </el-select>
                            </base-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <base-input
                                label="Cost Amount (R)*"
                                class="mb-3"
                                type="number"
                                placeholder="Enter an amount"
                                name="Cost Amount"
                                :rules="{ required: true }"
                                v-model="form.cost_amount"
                                :disabled="$apollo.loading && !form.create_mode"
                                @keyup="input_on_key_up_debounced"
                            >
                            </base-input>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
            <template #modal-footer="{ ok }">
                <b-button
                    variant="default"
                    @click="ok()"
                    :disabled="modal.loading || $apollo.loading"
                >
                    <i
                        v-if="modal.loading || $apollo.loading"
                        class="fas fa-spinner fa-spin"
                    ></i>
                    Save
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
// Modules
import { debounce } from "debounce";
import { Select, Option } from "element-ui";

//Custom Components

// Queries
import { GET_INVESTMENT_TRANSACTION_COST } from "@/graphql/queries";
import { GET_ALL_INVESTMENT_TRANSACTION_COST_TYPE } from "@/graphql/queries";

// Mutations
import { CREATE_INVESTMENT_TRANSACTION_COST } from "@/graphql/mutations";
import { UPDATE_INVESTMENT_TRANSACTION_COST } from "@/graphql/mutations";

export default {
    name: "CruOperatingCost",
    components: { [Select.name]: Select, [Option.name]: Option },
    emits: ["expense_changed"],
    props: {
        buying_cost_id: {
            type: String,
            description: "The id of the buying cost to be edited.",
            default: null,
        },
        investment_id: {
            type: String,
            description: "The id of the investment that this cost belongs to.",
            default: "",
        },
        show: {
            type: Boolean,
            description: "Weather to show the modal.",
            default: false,
        },
    },
    apollo: {
        get_buying_cost: {
            query: GET_INVESTMENT_TRANSACTION_COST,
            result(data) {
                this.handle_get_buying_cost(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_buying_cost = data;
            },
            variables() {
                return {
                    transaction_cost_id: this.buying_cost_id,
                };
            },
            skip: true,
        },
        get_all_transaction_cost_types: {
            query: GET_ALL_INVESTMENT_TRANSACTION_COST_TYPE,
            result(data) {
                this.handle_get_all_transaction_cost_types(data);
            },
            error(errors) {
                console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
                console.log(errors);
                return false;
            },
            update(data) {
                this.apollo_data.get_all_transaction_cost_types = data;
            },
        },
    },

    data() {
        return {
            modal: { show: false, loading: false },
            apollo_data: {
                get_buying_cost: null,
            },
            form: {
                transaction_cost_type: {
                    selection: null,
                    options: [
                        // {
                        //     label: "",
                        //     value: "",
                        // },
                    ],
                },
                cost_amount: null,
                busy: false,
            },
        };
    },
    methods: {
        // Modal Handlers
        handle_ok(bvModalEvent) {
            bvModalEvent.preventDefault();
            if (!this.$apollo.loading) {
                this.$refs.expense_form_submit_button.click();
            }
            this.modal.loading = true;
            setTimeout(() => {
                this.modal.show = false;
                this.modal.loading = false;
            }, 1000);
        },
        handle_modal_hide() {
            setTimeout(() => {
                this.form.transaction_cost_type.selection = null;
                this.form.cost_amount = null;
            }, 100);
        },

        // Form handlers
        input_on_key_up_debounced: debounce(function () {
            if (this.buying_cost_id != "") {
                this.$refs.expense_form_submit_button.click();
            }
        }, 1000),

        on_submit() {
            if (this.buying_cost_id == "") {
                this.create_expense();
            } else {
                this.update_expense();
            }
        },
        update_expense() {
            this.$apollo
                .mutate({
                    mutation: UPDATE_INVESTMENT_TRANSACTION_COST,
                    variables: {
                        cost_currency: "ZAR",
                        cost: this.form.cost_amount,
                        transaction_cost_type_id:
                            this.form.transaction_cost_type.selection,
                        transaction_cost_id: this.buying_cost_id,
                    },
                })
                .then((res) => {
                    this.$emit("buying_cost_changed", this.buying_cost_id);
                });
        },
        create_expense() {
            this.$apollo
                .mutate({
                    mutation: CREATE_INVESTMENT_TRANSACTION_COST,
                    variables: {
                        cost_currency: "ZAR",
                        cost: this.form.cost_amount,
                        transaction_cost_type_id:
                            this.form.transaction_cost_type.selection,
                        investment_id: this.investment_id,
                    },
                })
                .then((res) => {
                    let new_buying_cost_id = "";
                    if (
                        "investmentCreateTransactionCost" in res.data &&
                        "transactionCost" in
                            res.data.investmentCreateTransactionCost
                    ) {
                        new_buying_cost_id =
                            res.data.investmentCreateTransactionCost
                                .transactionCost.id;
                    }
                    this.$emit("buying_cost_changed", new_buying_cost_id);
                });
        },

        // Utils
        formatted_currency(value) {
            return value.toLocaleString();
        },

        // Apollo Managers
        enable_get_buying_cost() {
            this.$apollo.queries.get_buying_cost.setOptions({
                fetchPolicy: "network-only",
            });
            if (!this.$apollo.queries.get_buying_cost.skip) {
                this.$apollo.queries.get_buying_cost.refetch();
            } else {
                this.$apollo.queries.get_buying_cost.skip = false;
                this.$apollo.queries.get_buying_cost.start();
            }
            if (!this.$apollo.queries.get_buying_cost.skip) {
                this.$apollo.queries.get_buying_cost.refetch();
            } else {
                this.$apollo.queries.get_buying_cost.skip = false;
                this.$apollo.queries.get_buying_cost.start();
            }
        },

        // Apollo handlers
        handle_get_buying_cost(data) {
            this.form.transaction_cost_type.selection =
                data.data.investmentTransactionCost.transactionCostType.id;
            this.form.cost_amount =
                data.data.investmentTransactionCost.cost.amount;
        },

        handle_get_all_transaction_cost_types(data) {
            this.form.transaction_cost_type.options = [];
            let flattened = graph_utils.flatten_objects_recursive(
                graph_utils.apollo_to_obj_recursive(data.data)
            );

            flattened.allInvestmentTransactionCostType.forEach((element) => {
                this.form.transaction_cost_type.options.push({
                    label: element.friendlyName,
                    value: element.id,
                });
            });
        },
    },
    watch: {
        buying_cost_id(new_buying_cost_id) {
            if (new_buying_cost_id !== "") {
                this.enable_get_buying_cost();
            } else {
                this.$apollo.queries.get_buying_cost.skip = true;
                this.$apollo.queries.get_buying_cost.stop();
            }
        },
        show(new_show) {
            this.modal.show = new_show;
            if (this.buying_cost_id !== "") {
                this.enable_get_buying_cost();
            }
        },
    },
    mounted() {
        if (this.buying_cost_id !== "") {
            this.enable_get_buying_cost();
        }
        this.modal.show = this.show;
    },
};
</script>
<style>
.el-input__inner {
    border-radius: 10rem;
}
</style>
