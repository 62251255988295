import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import AuthLayout from "@/views/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "@/views/GeneralViews/NotFoundPage.vue";

// Calendar
const Calendar = () =>
    import(/* webpackChunkName: "extra" */ "@/views/Calendar/Calendar.vue");
// Charts
const Charts = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Charts.vue");

// Components pages
const Buttons = () =>
    import(
        /* webpackChunkName: "components" */ "@/views/Components/Buttons.vue"
    );
const Cards = () =>
    import(/* webpackChunkName: "components" */ "@/views/Components/Cards.vue");
const GridSystem = () =>
    import(
        /* webpackChunkName: "components" */ "@/views/Components/GridSystem.vue"
    );
const Notifications = () =>
    import(
        /* webpackChunkName: "components" */ "@/views/Components/Notifications.vue"
    );
const Icons = () =>
    import(/* webpackChunkName: "components" */ "@/views/Components/Icons.vue");
const Typography = () =>
    import(
        /* webpackChunkName: "components" */ "@/views/Components/Typography.vue"
    );

// Dashboard pages
const Dashboard = () =>
    import(
        /* webpackChunkName: "dashboard" */ "@/views/Dashboard/Dashboard.vue"
    );
const AlternativeDashboard = () =>
    import(
        /* webpackChunkName: "dashboard" */ "@/views/Dashboard/AlternativeDashboard.vue"
    );
const Widgets = () =>
    import(/* webpackChunkName: "dashboard" */ "@/views/Widgets.vue");

// Forms pages
const FormElements = () =>
    import(/* webpackChunkName: "forms" */ "@/views/Forms/FormElements.vue");
const FormComponents = () =>
    import(/* webpackChunkName: "forms" */ "@/views/Forms/FormComponents.vue");
const FormValidation = () =>
    import(/* webpackChunkName: "forms" */ "@/views/Forms/FormValidation.vue");

// Maps pages
const GoogleMaps = () =>
    import(/* webpackChunkName: "extra" */ "@/views/Maps/GoogleMaps.vue");
const VectorMaps = () =>
    import(/* webpackChunkName: "extra" */ "@/views/Maps/VectorMaps.vue");

// Pages
const UserProfile = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/UserProfile.vue");

const DiscoverServiceProviders = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/DiscoverServiceProviders.vue"
    );
const DiscoverServiceProvidersUnAuth = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/DiscoverServiceProvidersUnAuth.vue"
    );
const ViewServiceProvider = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/ViewServiceProvider.vue"
    );
const ViewServiceProviderUnAuth = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/ViewServiceProviderUnAuth.vue"
    );

const ListingDashboard = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/ListingDashboard.vue"
    );

const ManageListings = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ManageListings.vue");
const ManageListing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ManageListing.vue");

const CRUDListing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/CRUDListing.vue");
const DiscoverListings = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/DiscoverListings.vue"
    );
const DiscoverListingsUnauth = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/DiscoverListingsUnauth.vue"
    );
const ViewListing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ViewListing.vue");

const ViewListingUnAuth = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/ViewListingUnAuth.vue"
    );

const PreviewListing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/PreviewListing.vue");
const ListingInvestments = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/ListingInvestments.vue"
    );

const Pricing = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Pricing.vue");
const TimeLine = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/TimeLinePage.vue");
const Login = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Login.vue");
const ForgotPassword = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ForgotPassword.vue");
const ResetPassword = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ResetPassword.vue");
const Logout = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Logout.vue");
const LoginExpired = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/LoginExpired.vue");
const Home = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Home.vue");
const Register = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Register.vue");
const TermsAndConditions = () =>
    import(
        /* webpackChunkName: "pages" */ "@/views/Pages/TermsAndConditions.vue"
    );
const PrivacyPolicy = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/PrivacyPolicy.vue");
const Lock = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/Lock.vue");

// TableList pages
const RegularTables = () =>
    import(/* webpackChunkName: "tables" */ "@/views/Tables/RegularTables.vue");
const SortableTables = () =>
    import(
        /* webpackChunkName: "tables" */ "@/views/Tables/SortableTables.vue"
    );
const PaginatedTables = () =>
    import(
        /* webpackChunkName: "tables" */ "@/views/Tables/PaginatedTables.vue"
    );

const ComponentDev = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/ComponentDev.vue");

const MyPortfolio = () =>
    import(/* webpackChunkName: "pages" */ "@/views/Pages/MyPortfolio.vue");

let componentsMenu = {
    path: "/components",
    component: DashboardLayout,
    redirect: "/components/buttons",
    name: "Components",
    children: [
        {
            path: "buttons",
            name: "Buttons",
            component: Buttons,
        },
        {
            path: "cards",
            name: "Cards",
            component: Cards,
        },
        {
            path: "grid-system",
            name: "Grid System",
            component: GridSystem,
        },
        {
            path: "notifications",
            name: "Notifications",
            component: Notifications,
        },
        {
            path: "icons",
            name: "Icons",
            component: Icons,
        },
        {
            path: "typography",
            name: "Typography",
            component: Typography,
        },
    ],
};
let formsMenu = {
    path: "/forms",
    component: DashboardLayout,
    redirect: "/forms/elements",
    name: "Forms",
    children: [
        {
            path: "elements",
            name: "Form elements",
            component: FormElements,
        },
        {
            path: "components",
            name: "Form components",
            component: FormComponents,
        },
        {
            path: "validation",
            name: "Form validation",
            component: FormValidation,
        },
    ],
};

let tablesMenu = {
    path: "/tables",
    component: DashboardLayout,
    redirect: "/table/regular",
    name: "Tables menu",
    children: [
        {
            path: "regular",
            name: "Tables",
            component: RegularTables,
        },
        {
            path: "sortable",
            name: "Sortable",
            component: SortableTables,
        },
        {
            path: "paginated",
            name: "Paginated Tables",
            component: PaginatedTables,
        },
    ],
};

let mapsMenu = {
    path: "/maps",
    component: DashboardLayout,
    name: "Maps",
    redirect: "/maps/google",
    children: [
        {
            path: "google",
            name: "Google Maps",
            component: GoogleMaps,
        },
        {
            path: "vector",
            name: "Vector Map",
            component: VectorMaps,
        },
    ],
};

let userPagesMenu = {
    path: "/user",
    component: DashboardLayout,
    name: "User",
    redirect: "/user/profile",
    children: [
        {
            path: "profile",
            name: "Profile",
            component: UserProfile,
        },
        {
            path: "timeline",
            name: "Timeline Page",
            component: TimeLine,
        },
    ],
};

let ListingPagesUnauth = {
    path: "/ulisting",
    component: AuthLayout,
    name: "Listings",
    redirect: "/ulisting/discover",
    children: [
        {
            path: "/ulisting/discover",
            name: "DiscoverListingsUnAuth",
            component: DiscoverListingsUnauth,
        },
        {
            path: "/ulisting/view",
            name: "ViewListingUnAuth",
            component: ViewListingUnAuth,
        },
    ],
};

let ServiceProviderPagesUnAuth = {
    path: "/usp",
    component: AuthLayout,
    name: "ListingsUnAuth",
    redirect: "/usp/discover",
    children: [
        {
            path: "/service-providers/discover",
            name: "ServiceProvidersUnAuth",
            component: DiscoverServiceProvidersUnAuth,
        },
        {
            path: "/service-providers/view",
            name: "ViewServiceProviderUnAuth",
            component: ViewServiceProviderUnAuth,
        },
    ],
};

let authPages = {
    path: "/",
    component: AuthLayout,
    name: "Authentication",
    children: [
        // {
        //     path: "/home",
        //     name: "Home",
        //     component: Home,
        //     meta: {
        //         noBodyBackground: true,
        //     },
        // },
        {
            path: "/login",
            name: "Login",
            component: Login,
        },
        {
            path: "/logout",
            name: "Logout",
            component: Logout,
        },
        {
            path: "/login-expired",
            name: "LoginExpired",
            component: LoginExpired,
        },
        {
            path: "/forgot_password",
            name: "ForgotPassword",
            component: ForgotPassword,
        },

        {
            path: "/reset_password",
            name: "ResetPassword",
            component: ResetPassword,
        },

        {
            path: "/register",
            name: "Register",
            component: Register,
        },
        {
            path: "/tnc",
            name: "TermsAndConditions",
            component: TermsAndConditions,
        },
        {
            path: "/privacy",
            name: "PrivacyPolicy",
            component: PrivacyPolicy,
        },
        // {
        //     path: "/lock",
        //     name: "Lock",
        //     component: Lock,
        // },
        { path: "/not-found", component: NotFound },
        { path: "*", component: NotFound },
    ],
};

let listingCrudPages = {
    path: "/listing",
    component: DashboardLayout,
    name: "Listing",
    redirect: "/listing/discover",
    children: [
        {
            path: "my-listings",
            name: "My Listings",
            component: ListingDashboard,
        },
        {
            path: "create",
            name: "CreateListing",
            component: CRUDListing,
        },
        {
            path: "manage-investments",
            name: "ListingInvestments",
            component: ListingInvestments,
        },
        {
            path: "edit",
            name: "EditListing",
            component: CRUDListing,
        },
        {
            path: "discover",
            name: "DiscoverListings",
            component: DiscoverListings,
        },
        {
            path: "view",
            name: "ViewListing",
            component: ViewListing,
        },
        {
            path: "preview/:listing_id",
            name: "PreviewListing",
            component: PreviewListing,
        },
        {
            path: "manage",
            name: "Manage Listings",
            component: ManageListings,
        },
        {
            path: "manage/:listing_id",
            name: "Manage Listing",
            component: ManageListing,
        },
    ],
};

let ServiceProviderPages = {
    path: "/sp",
    component: DashboardLayout,
    name: "Service Providers",
    redirect: "/sp/discover",
    children: [
        {
            path: "discover",
            name: "Discover Service Providers",
            component: DiscoverServiceProviders,
        },
        {
            path: "view",
            name: "View Service Provider",
            component: ViewServiceProvider,
        },
    ],
};

const routes = [
    // componentsMenu,
    // formsMenu,
    // tablesMenu,
    // mapsMenu,
    listingCrudPages,
    ServiceProviderPages,
    userPagesMenu,

    // Unauthed Pages
    ListingPagesUnauth,
    ServiceProviderPagesUnAuth,

    // {
    //     path: "/",
    //     redirect: "/dash",
    //     name: "Home",
    // },
    {
        path: "/",
        component: DashboardLayout,
        redirect: "/listing/discover",
        name: "Home",
        children: [
            {
                path: "my-portfolio",
                name: "MyPortfolio",
                component: MyPortfolio,
            },
            // ##################################
            // Component Dev
            // ##################################
            {
                path: "componentdev",
                name: "componentdev",
                component: ComponentDev,
            },
            // {
            //     path: "my-listings",
            //     name: "My Listings",
            //     component: ListingDashboard,
            // },
            // {
            //     path: "alternative",
            //     name: "Alternative",
            //     component: AlternativeDashboard,
            //     meta: {
            //         navbarType: "light",
            //     },
            // },
            // {
            //     path: "calendar",
            //     name: "Calendar",
            //     component: Calendar,
            // },
            // {
            //     path: "charts",
            //     name: "Charts",
            //     component: Charts,
            // },
            // {
            //     path: "widgets",
            //     name: "Widgets",
            //     component: Widgets,
            // },
        ],
    },
    authPages,
];

export default routes;
