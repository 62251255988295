import gql from "graphql-tag";

export const GET_USER_PROFILE_CONTACT_DETAIL = gql`
    query ($b64_user_id: ID!) {
        allUserProfileContactDetail(user: $b64_user_id) {
            edges {
                node {
                    phoneNumber
                    secondaryPhoneNumber
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTING_TYPE_OPTIONS = gql`
    query {
        allPropertyListingType {
            edges {
                node {
                    id
                    name
                }
            }
        }
    }
`;
export const GET_ALL_USER_ACCOUNT_ACCOUNT = gql`
    query ($user: ID!) {
        allUserAccountAccount(owner: $user) {
            edges {
                node {
                    id
                    uid
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTING_FOR_ACCOUNT = gql`
    query ($account_id: ID!) {
        allPropertyListing(listerAccount: $account_id) {
            edges {
                node {
                    id
                    uid
                    name
                    approval {
                        id
                        state {
                            id
                            name
                            friendlyName
                        }
                    }
                    status {
                        id
                        name
                        friendlyName
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id
                                bedroomCount
                                bathroomCount
                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }

                    address {
                        gAddress
                        id
                    }
                }
            }
        }
    }
`;

export const GET_ALL_PROPERTY_LISTING = gql`
    query {
        allPropertyListing {
            edges {
                node {
                    id
                    uid
                    listerUser {
                        id
                        username
                        firstName
                        lastName
                    }
                    name
                    status {
                        id
                        name
                        friendlyName
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id
                                bedroomCount
                                bathroomCount
                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }
                    address {
                        gAddress
                        id
                    }
                }
            }
        }
    }
`;

export const GET_ALL_PROPERTY_LISTING_MANAGE_VIEW = gql`
    query {
        allPropertyListing {
            edges {
                node {
                    id
                    uid
                    listerUser {
                        id
                        username
                        firstName
                        lastName
                    }
                    name
                    status {
                        id
                        name
                        friendlyName
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    approval {
                        id
                        state {
                            friendlyName
                            name
                            id
                        }
                    }

                    address {
                        gAddress
                        id
                    }
                }
            }
        }
    }
`;

export const GET_ALL_PROPERTY_LISTING_SATUSES = gql`
    query {
        allPropertyListingStatus {
            edges {
                node {
                    id
                    name
                    friendlyName
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_SATUS = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            status {
                id
                name
                friendlyName
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTINGS_PUBLISHED = gql`
    query ($listing_status_id: ID!) {
        allPropertyListing(status: $listing_status_id) {
            edges {
                node {
                    id
                    uid
                    name
                    status {
                        name
                        id
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id
                                bedroomCount
                                bathroomCount
                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }

                    address {
                        id
                        gAddress
                        suburbName
                        cityName
                    }
                    artifacts(first: 1) {
                        edges {
                            node {
                                id
                                file
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTINGS_STATUS_FILTER = gql`
    query ($listing_status_ids: [ID]!) {
        allPropertyListing(status_In: $listing_status_ids) {
            edges {
                node {
                    id
                    uid
                    name
                    status {
                        name
                        friendlyName
                        id
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id
                                bedroomCount
                                bathroomCount
                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }

                    address {
                        id
                        gAddress
                        suburbName
                        cityName
                    }
                    artifacts(first: 1) {
                        edges {
                            node {
                                id
                                file
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTINGS_UNAUTH = gql`
    query {
        allPropertyListing {
            edges {
                node {
                    id
                    uid
                    name
                    status {
                        name
                        friendlyName
                        id
                    }
                    listingType {
                        name
                        friendlyName
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id
                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }
                    address {
                        id
                        cityName
                    }
                    artifacts(first: 1) {
                        edges {
                            node {
                                id
                                file
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PROPERTY_LISTING_RATES_AND_TAXES = gql`
    query ($listing_id: ID!) {
        allPropertyListingRatesAndTaxes(listing: $listing_id) {
            edges {
                node {
                    id
                    monthlyAmountCurrency
                    monthlyAmount {
                        amount
                        currency {
                            symbol
                        }
                    }
                    annualEscalationPercentage
                }
            }
        }
    }
`;

export const GET_PROPERTY_LISTING_LEVY = gql`
    query ($listing_id: ID!) {
        allPropertyListingLevy(listing: $listing_id) {
            edges {
                node {
                    id
                    monthlyAmountCurrency
                    monthlyAmount {
                        amount
                        currency {
                            symbol
                        }
                    }
                    annualEscalationPercentage
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_RENTAL_APPROACH = gql`
    query {
        allPropertyRentalApproach {
            edges {
                node {
                    id
                    name
                    friendlyName
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_SUBUNIT_TYPE_ROOM = gql`
    query {
        allPropertySubunitType(name: "room") {
            edges {
                node {
                    id
                    name
                    friendlyName
                }
            }
        }
    }
`;

export const GET_ALL_PROPERTY_LISTING_ROOMS = gql`
    query ($listing_id: ID, $subunitType_id: ID!) {
        allPropertySubunit(listing: $listing_id, subunitType: $subunitType_id) {
            edges {
                node {
                    id
                    description
                    subunitType {
                        id
                        name
                    }
                    expectedRentalIncome {
                        amount
                        currency {
                            symbol
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PROPERTY_SUBUNIT = gql`
    query ($subunit_id: ID!) {
        propertySubunit(id: $subunit_id) {
            id
            toBeCreated
            isPartitioned
            description
            sizeM2
            expectedRentalIncome {
                amount
                currency {
                    symbol
                }
            }
            hasSharedKitchen
            hasSharedBathroom
            isFurnished
            currentRentalIncome {
                amount
                currency {
                    symbol
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_MODIFICATION_TYPE = gql`
    query {
        allPropertyModificationType {
            edges {
                node {
                    name
                    friendlyName
                    id
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_BASIC = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            name
            description
            amenities
            status {
                id
                name
                friendlyName
            }
            address {
                id
                gPlaceId
                gAddress
            }
            buyingPrice {
                amount
                currency {
                    symbol
                }
            }
            marketPrice {
                amount
                currency {
                    symbol
                }
            }
            listingType {
                id
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_VIEW_BASIC = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            name
            description
            amenities
            address {
                id
                cityName
            }
            status{
                id
                name
                friendlyName
            }
            buyingPrice {
                amount
                currency {
                    symbol
                }
            }
            totalExpectedRentalIncome {
                amount
                currency {
                    symbol
                }
            }
            marketPrice {
                amount
                currency {
                    symbol
                }
            }
            listingType {
                id
                friendlyName
                name
            }
            subunits {
                edges {
                    node {
                        id
                        bedroomCount
                        bathroomCount
                    }
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_LISTING_MAIN_SUBUNIT = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            name
            description
            amenities
            address {
                id
                suburbName
                cityName
            }
            buyingPrice {
                amount
                currency {
                    symbol
                }
            }
            marketPrice {
                amount
                currency {
                    symbol
                }
            }
            listingType {
                id
                friendlyName
            }
            subunits {
                edges {
                    node {
                        id
                        bedroomCount
                        bathroomCount
                    }
                }
            }
        }
    }
`;
export const GET_ALL_PROPERTY_SUBUNIT_TYPES = gql`
    query {
        allPropertySubunitType {
            edges {
                node {
                    name
                    id
                    friendlyName
                }
            }
        }
    }
`;

export const GET_PROPERTY_SUBUNIT_MAIN = gql`
    query ($listing_id: ID!, $subunit_type_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            subunits(subunitType: $subunit_type_id) {
                edges {
                    node {
                        id
                        maxOccupantCount
                        allowsSubletting
                        subunitType {
                            name
                            friendlyName
                            id
                        }
                        futureRentalApproach {
                            name
                            friendlyName
                            id
                        }
                        expectedRentalIncome {
                            amount
                            currency {
                                symbol
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_SUBUNITS_LIGHT = gql`
    query ($listing_id: ID!) {
        allPropertySubunit(listing: $listing_id) {
            edges {
                node {
                    id
                    subunitType {
                        id
                        name
                        friendlyName
                    }
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_ARTIFACTS = gql`
    query ($listing_id: ID!) {
        allPropertyArtifact(listing: $listing_id) {
            edges {
                node {
                    id
                    file
                    listing {
                        id
                    }
                    subunit {
                        id
                    }
                    description
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_OWNED_BY_USER = gql`
    query ($user_id: ID!) {
        allPropertyListing(owner: $user_id) {
            edges {
                node {
                    id
                    uid
                    name
                    status {
                        name
                        friendlyName
                        id
                    }
                    listingType {
                        name
                        id
                    }
                    buyingPrice {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    totalExpectedRentalIncome {
                        amount
                        currency {
                            code
                            symbol
                        }
                    }
                    subunits {
                        edges {
                            node {
                                id

                                expectedRentalIncome {
                                    amount
                                    currency {
                                        symbol
                                    }
                                }
                                isOnlySubunit
                            }
                        }
                        totalCount
                    }

                    address {
                        id
                        cityName
                        gAddress
                    }
                    artifacts(first: 1) {
                        edges {
                            node {
                                id
                                file
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GET_USER_PROFILE = gql`
    query ($user_id: ID!) {
        allUserProfile(user: $user_id) {
            edges {
                node {
                    id
                    roles {
                        edges {
                            node {
                                id
                                name
                                friendlyName
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_ALL_SERVICE_PROVIDER_SERVICE_PROVIDERS = gql`
    query {
        allServiceProvidersServiceProvider {
            edges {
                node {
                    id
                    name
                    type {
                        id
                        name
                        friendlyName
                    }
                    description
                    locationCity
                    locationProvinceFriendlyName
                    photo {
                        file
                    }
                    rating {
                        id
                        stars
                        submissions {
                            totalCount
                        }
                    }
                }
            }
        }
    }
`;

export const GET_SERVICE_PROVIDER_SERVICE_PROVIDER = gql`
    query ($id: ID!) {
        serviceProvidersServiceProvider(id: $id) {
            id
            name
            rating {
                id
                stars
                submissions {
                    totalCount
                }
            }
            type {
                id
                name
                friendlyName
            }
            services {
                edges {
                    node {
                        id
                        name
                    }
                }
            }
            description
            detailDescription
            
            locationCity
            locationProvinceFriendlyName
            photo {
                file
            }
        }
    }
`;
export const GET_ALL_INVESTMENT_RECURRING_EXPENSES = gql`
    query ($investment_id: ID!) {
        allInvestmentRecurringExpense(investment: $investment_id) {
            edges {
                node {
                    id
                    investment {
                        id
                    }
                    description
                    cost {
                        amount
                        currency {
                            symbol
                        }
                    }
                }
            }
        }
    }
`;
export const GET_INVESTMENT_RECURRING_EXPENSE = gql`
    query ($expense_id: ID!) {
        investmentRecurringExpense(id: $expense_id) {
            id
            description
            cost {
                amount
                currency {
                    symbol
                }
            }
        }
    }
`;

export const GET_ALL_INVESTMENT_TRANSACTION_COSTS = gql`
    query ($investment_id: ID!) {
        allInvestmentTransactionCost(investment: $investment_id) {
            edges {
                node {
                    id
                    transactionCostType {
                        id
                        name
                        friendlyName
                        isDefaultTransactionCost
                    }
                    cost {
                        amount
                        currency {
                            symbol
                        }
                    }
                }
            }
        }
    }
`;
export const GET_INVESTMENT_TRANSACTION_COST = gql`
    query ($transaction_cost_id: ID!) {
        investmentTransactionCost(id: $transaction_cost_id) {
            id
            transactionCostType {
                id
                name
                friendlyName
            }
            cost {
                amount
                currency {
                    symbol
                }
            }
        }
    }
`;
export const GET_ALL_INVESTMENT_TRANSACTION_COST_TYPE = gql`
    query {
        allInvestmentTransactionCostType {
            edges {
                node {
                    name
                    friendlyName
                    id
                }
            }
        }
    }
`;
export const GET_INVESTMENT_INVESTMENT_FINANCING = gql`
    query ($investment_id: ID!) {
        investmentInvestment(id: $investment_id) {
            id
            listing {
                name
                buyingPrice {
                    amount
                }
            }
            financingSet(first: 1) {
                edges {
                    node {
                        id
                        financingType {
                            name
                            friendlyName
                            id
                        }
                        bonds(first: 1) {
                            edges {
                                node {
                                    id
                                    projectedInterestRatePercentage
                                    termMonths
                                    monthlyRepayments {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                    totalBondPrincipalAmount {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                    deposit {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_PROPERTY_LISTING_INVESTMENTS = gql`
    query ($listing_id: ID!) {
        allInvestmentInvestment(listing: $listing_id) {
            edges {
                node {
                    id
                }
            }
        }
    }
`;

export const GET_PROPERTY_LISTING_NAME = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            name
        }
    }
`;
export const GET_ALL_INVESTMENT_FINANCING_TYPES = gql`
    query {
        allInvestmentFinancingType {
            edges {
                node {
                    id
                    name
                    friendlyName
                }
            }
        }
    }
`;

// Used on the View listing page as a representation of the financial model.
export const GET_LISTING_INVESTMENT_FINANCING = gql`
    query ($listing_id: ID!) {
        propertyListing(id: $listing_id) {
            id
            name
            buyingPrice {
                amount
                currency {
                    symbol
                }
            }
            subunits {
                edges {
                    node {
                        id
                        description
                        expectedRentalIncome {
                            amount
                            currency {
                                symbol
                            }
                        }
                        children {
                            totalCount
                        }
                        parentSubunit {
                            id
                            description
                        }
                        subunitType {
                            id
                            friendlyName
                            name
                        }
                    }
                }
            }

            investmentSet {
                edges {
                    node {
                        id

                        recurringexpenseSet {
                            edges {
                                node {
                                    id
                                    description
                                    cost {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                }
                            }
                        }
                        transactioncostSet {
                            edges {
                                node {
                                    id
                                    transactionCostType {
                                        id
                                        name
                                        friendlyName
                                    }
                                    cost {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                }
                            }
                        }
                        financingSet(first: 1) {
                            edges {
                                node {
                                    id
                                    financingType {
                                        name
                                        friendlyName
                                        id
                                    }
                                    bonds(first: 1) {
                                        edges {
                                            node {
                                                id
                                                projectedInterestRatePercentage
                                                termMonths
                                                monthlyRepayments {
                                                    amount
                                                    currency {
                                                        symbol
                                                    }
                                                }
                                                totalBondPrincipalAmount {
                                                    amount
                                                    currency {
                                                        symbol
                                                    }
                                                }
                                                deposit {
                                                    amount
                                                    currency {
                                                        symbol
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
export const GET_PROPERTY_LISTING_INTEREST_USER = gql`
    query ($user_id: ID!, $listing_id: ID!) {
        allPropertyInterest(user: $user_id, listing: $listing_id) {
            totalCount
        }
    }
`;
export const GET_PROPERTY_LISTINGS_INTEREST_USER = gql`
    query ($user_id: ID!) {
        allPropertyInterest(user: $user_id) {
            edges {
                node {
                    id
                    listing {
                        id
                        uid
                        name
                        status {
                            name
                            friendlyName
                            id
                        }
                        listingType {
                            name
                            id
                        }
                        buyingPrice {
                            amount
                            currency {
                                code
                                symbol
                            }
                        }
                        totalExpectedRentalIncome {
                            amount
                            currency {
                                code
                                symbol
                            }
                        }
                        subunits {
                            edges {
                                node {
                                    id

                                    expectedRentalIncome {
                                        amount
                                        currency {
                                            symbol
                                        }
                                    }
                                    isOnlySubunit
                                }
                            }
                            totalCount
                        }

                        address {
                            id
                            cityName
                        }
                        artifacts(first: 1) {
                            edges {
                                node {
                                    id
                                    file
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const GET_USER_ACCOUNT_AGREEMENTS = gql`
    query ($account_id: ID!) {
        userAccountAccount(id: $account_id) {
            uid
            agreements {
                totalCount
                edges {
                    node {
                        id
                        agreementType {
                            name
                        }
                    }
                }
            }
        }
    }
`;
export const GET_PROPERTY_MODIFICATIONS_SUBUNIT = gql`
    query ($subunit_id: ID!) {
        allPropertyModification(subunit: $subunit_id) {
            edges {
                node {
                    id
                    description
                    expectedCost {
                        amount
                        currency {
                            symbol
                        }
                    }
                    modificationType {
                        name
                        friendlyName
                        id
                    }
                }
            }
        }
    }
`;
export const GET_PROPERTY_MODIFICATIONS_LISTING = gql`
    query ($listing_id: ID!) {
        allPropertyModification(listing: $listing_id) {
            edges {
                node {
                    id
                    description
                    expectedCost {
                        amount
                        currency {
                            symbol
                        }
                    }
                    modificationType {
                        name
                        friendlyName
                        id
                    }
                }
            }
        }
    }
`;

export const GET_PROPERTY_SUBUNIT_FULL_DETAIL = gql`
    query ($subunit_id: ID!) {
        propertySubunit(id: $subunit_id) {
            id
            subunitType {
                id
                name
                friendlyName
            }
            bedroomCount
            bathroomCount
            sizeM2
            expectedRentalIncome {
                amount
                currency {
                    symbol
                }
            }
        }
    }
`;
export const GET_APPROVAL_APPROVAL_DETAIL = gql`
    query ($approval_id: ID!) {
        approvalApproval(id: $approval_id) {
            state {
                id
                name
                friendlyName
            }
            itemType {
                supportedStates {
                    edges {
                        node {
                            id
                            name
                            friendlyName
                        }
                    }
                }
            }
            stateUpdates {
                edges {
                    node {
                        id
                        newState {
                            id
                            name
                            friendlyName
                        }
                        comment
                        created
                    }
                }
            }
        }
    }
`;

export const GET_ALL_RATING_SUBMISSIONS = gql`
    query ($rating_id: ID!) {
        allRatingRatingSubmission(rating: $rating_id) {
            edges {
                node {
                    id
                    modified
                    stars
                    comment
                    headline
                }
            }
        }
    }
`;
export const GET_RATING_SUBMISSION = gql`
    query ($rating_submission_id: ID!) {
        ratingRatingSubmission(id: $rating_submission_id) {
            id
            modified
            stars
            comment
            headline
        }
    }
`;
export const GET_CONTRACT_CONTRACT = gql`
    query ($contract_id: ID!) {
        contractContract(id: $contract_id) {
            id
            name
            versions(last: 1) {
                edges {
                    node {
                        id
                        htmlContent
                    }
                }
            }
        }
    }
`;
export const GET_CONTRACT_EXECUTIONS_BY_CONTRACT_TYPE_AND_USER = gql`
    query ($user_id: ID!, $contract_type_name: String!) {
        allContractContractType(name: $contract_type_name) {
            edges {
                node {
                    id
                    contracts(last: 1) {
                        edges {
                            node {
                                id
                                versions(last: 1, active: true) {
                                    edges {
                                        node {
                                            id
                                            executions(user: $user_id) {
                                                edges {
                                                    node {
                                                        id
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
